import React from 'react';
import { useSelector } from 'react-redux';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import SanyasIcon from 'components/shared/SanyasIcon';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import ActionsMenu from './ActionsMenu';
import StatusLabel from './StatusLabel';
import './styles.scss';

function Header() {
  const { group } = useSelector((state) => state.groupShow);
  const { id: workspaceId, path } = useWorkspace();

  return (
    <div className="group-header">
      <SanyasIcon name="people" size="3x" />
      <div>
        <Breadcrumbs
          navItems={[
            { label: 'Groups', href: `${path}/groups` },
            { label: group?.name || '...' },
          ]}
        />

        <h1 className="mb-0">{group?.name || '...'}</h1>
      </div>

      <StatusLabel />

      <div className="ms-auto">
        {workspaceId === 'registrar' && <ActionsMenu />}
      </div>
    </div>
  );
}

export default Header;
