import {
  REGISTRAR_GROUP_NEW_PATH,
  REGISTRAR_GROUP_SHOW_PATH,
  REGISTRAR_GROUP_IMPORT_PATH,
  REGISTRAR_REPORTS_EMAIL_TRANSMISSION_PATH,
  REGISTRAR_REPORTS_PAYMENTS_PATH,
  REGISTRAR_REPORTS_PLATFORM_PTQ_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_NEW_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_SHOW_PATH,
  REGISTRAR_SETTINGS_PLATFORM_CLOSURES_EDIT_PATH,
  REGISTRAR_SETTINGS_ORGANIZATION_EMAIL_TEMPLATES_PATH,
  REGISTRAR_REPORTS_RESPONSES_PATH,
  REGISTRAR_VOUCHERS_PATH,
  REGISTRAR_VOUCHERS_NEW_PATH,
  REGISTRAR_VOUCHERS_SHOW_PATH,
  REGISTRAR_USERS_SHOW_PATH,
  FACILITATOR,
  FACILITATOR_DASHBOARD_PATH,
  FACILITATOR_MESSAGES_PATH,
  FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH,
  FACILITATOR_GROUP_SHOW_PATH,
  FACILITATOR_PARTICIPANTS_SHOW_PATH,
  FACILITATOR_CURRICULUM_PATH,
  FACILITATOR_COURSE_TYPE_PATH,
  FACILITATOR_COURSE_PATH,
  FACILITATOR_COURSE_GLOSSARY_PATH,
  FACILITATOR_COURSE_PHRASEBOOK_PATH,
  FACILITATOR_COURSE_SEARCH_PATH,
  FACILITATOR_COURSE_SELF_CARE_PATH,
  FACILITATOR_COURSE_COMPLETION_REPORT_PATH,
  FACILITATOR_DISCUSSION_BOARDS_PATH,
  FACILITATOR_COMMENTS_PATH,
  SUPER_FACILITATOR,
  SUPER_FACILITATOR_DASHBOARD_PATH,
  SUPER_FACILITATOR_REPORTS_PATH,
  SUPER_FACILITATOR_DISCUSSION_BOARD_REPORT_PATH,
  SUPER_FACILITATOR_JOURNAL_REPORT_PATH,
  CURRICULUM_PATH,
  CURRICULUM_COURSE_TYPE_SHOW_PATH,
  CURRICULUM_COURSE_SHOW_PATH,
  CURRICULUM_COURSE_EMAIL_TEMPLATES_PATH,
  CURRICULUM_COURSE_GLOSSARY_PATH,
  CURRICULUM_COURSE_PHRASEBOOK_PATH,
  CURRICULUM_COURSE_FIND_AND_REPLACE_PATH,
  CURRICULUM_COURSE_PROACTIVE_TEMPLATES_PATH,
  CURRICULUM_COURSE_COMPLETION_REPORT_PATH,
  CURRICULUM_COURSE_SELF_CARE_PATH,
  REPORTING,
  REPORTING_HARM_REPORT,
} from 'lib/routerPaths';
import EmailTransmissionReportPage from 'pages/reporting/EmailTransmissionReportPage';
import PaymentReportPage from 'pages/reporting/PaymentReportPage/PaymentReportPage';
import FacilitatorDashboard from 'pages/facilitator/FacilitatorDashboard';
import FacilitatorCurriculum from 'pages/facilitator/FacilitatorCurriculum';
import IndigenousWelcomeLettersPage from 'pages/facilitator/IndigenousWelcomeLettersPage';
import MessagesPage from 'pages/facilitator/MessagesPage';
import SuperFacilitatorDashboard from 'pages/superFacilitator/SuperFacilitatorDashboard';
import SuperFacilitatorReports from 'pages/superFacilitator/SuperFacilitatorReports';
import SuperFacilitatorDiscussionBoardReport from 'pages/reporting/SuperFacilitatorDiscussionBoardReport';
import SuperFacilitatorJournalReport from 'pages/reporting/FacilitatorReportPage';
import GroupCreatePage from 'pages/registrar/GroupCreatePage';
import GroupShowPage from 'pages/registrar/GroupShowPage';
import GroupParticipantsBulkImport from 'pages/groups/GroupParticipantsBulkImport';
import PlatformClosurePage from 'pages/registrar/PlatformClosurePage';
import PlatformClosureCreatePage from 'pages/registrar/PlatformClosureCreatePage';
import PlatformClosureShowPage from 'pages/registrar/PlatformClosureShowPage';
import PlatformClosureEditPage from 'pages/registrar/PlatformClosureEditPage';
import OrganizationEmailTemplatesPage from 'pages/registrar/OrganizationEmailTemplatesPage';
import VouchersPage from 'pages/registrar/VouchersPage';
import VouchersCreatePage from 'pages/registrar/VouchersCreatePage';
import VouchersShowPage from 'pages/registrar/VouchersShowPage';
import CurriculumPage from 'pages/curriculum/CurriculumPage';
import CourseTypeShowPage from 'pages/curriculum/CourseTypeShowPage';
import CourseShowPage from 'pages/curriculum/CourseShowPage';
import CourseEmailTemplatesPage from 'pages/curriculum/CourseEmailTemplatesPage/CourseEmailTemplatesPage';
import CourseGlossaryPage from 'pages/curriculum/CourseGlossaryPage/CourseGlossaryPage';
import CoursePhrasebookPage from 'pages/curriculum/CoursePhrasebookPage/CoursePhrasebookPage';
import CourseFindAndReplacePage from 'pages/curriculum/CourseFindAndReplacePage';
import CourseProactiveTemplatesPage from 'pages/curriculum/CourseProactiveTemplatesPage/CourseProactiveTemplatesPage';
import CourseCompletionReportPage from 'pages/curriculum/CourseCompletionReportPage';
import CourseSelfCarePage from 'pages/curriculum/CourseSelfCarePage';
import HarmPreventionReportPage from 'pages/reporting/HarmPreventionReportPage';
import PtqPlatformReportPage from 'pages/reporting/PtqPlatformReportPage';
import ReportingDashboard from 'pages/reporting/ReportingDashboard';
import ResponsesReportPage from 'pages/reporting/ResponsesReportPage';
import UsersShowPage from 'pages/registrar/UsersShowPage';
import FacilitatorCourseTypeShowPage from 'pages/facilitator/CourseTypeShowPage';
import FacilitatorCourseShowPage from 'pages/facilitator/CourseShowPage';
import FacilitatorCourseGlossaryPage from 'pages/facilitator/CourseGlossaryPage';
import FacilitatorCoursePhrasebookPage from 'pages/facilitator/CoursePhrasebookPage';
import FacilitatorCourseSearchPage from 'pages/facilitator/CourseSearchPage';
import FacilitatorCourseSelfCarePage from 'pages/facilitator/CourseSelfCarePage';
import FacilitatorCourseComletionReportPage from 'pages/facilitator/CourseCompletionReportPage';
import FacilitatorGroupShowPage from 'pages/facilitator/groups/GroupShowPage';
import FacilitatorCommentsShowPage from 'pages/facilitator/CommentsShowPage';
import DiscussionBoardsPage from 'pages/facilitator/DiscussionBoardsPage';
import ParticipantsShowPage from 'pages/facilitator/Participants/ParticipantsShowPage';

const registrarRoutes = [
  {
    path: REGISTRAR_GROUP_NEW_PATH,
    main: GroupCreatePage,
    exact: true,
  },
  {
    path: REGISTRAR_GROUP_IMPORT_PATH,
    main: GroupParticipantsBulkImport,
    exact: true,
  },
  {
    path: REGISTRAR_GROUP_SHOW_PATH,
    main: GroupShowPage,
  },
  {
    path: REGISTRAR_REPORTS_EMAIL_TRANSMISSION_PATH,
    main: EmailTransmissionReportPage,
    exact: true,
  },
  {
    path: REGISTRAR_REPORTS_PAYMENTS_PATH,
    main: PaymentReportPage,
    exact: true,
  },
  {
    path: REGISTRAR_REPORTS_PLATFORM_PTQ_PATH,
    main: PtqPlatformReportPage,
    exact: true,
  },
  {
    path: REGISTRAR_SETTINGS_PLATFORM_CLOSURES_PATH,
    main: PlatformClosurePage,
    exact: true,
  },
  {
    path: REGISTRAR_SETTINGS_PLATFORM_CLOSURES_NEW_PATH,
    main: PlatformClosureCreatePage,
    exact: true,
  },
  {
    path: REGISTRAR_SETTINGS_PLATFORM_CLOSURES_SHOW_PATH,
    main: PlatformClosureShowPage,
    exact: true,
  },
  {
    path: REGISTRAR_SETTINGS_PLATFORM_CLOSURES_EDIT_PATH,
    main: PlatformClosureEditPage,
    exact: true,
  },
  {
    path: REGISTRAR_SETTINGS_ORGANIZATION_EMAIL_TEMPLATES_PATH,
    main: OrganizationEmailTemplatesPage,
    exact: true,
  },
  {
    path: REGISTRAR_REPORTS_RESPONSES_PATH,
    main: ResponsesReportPage,
    exact: true,
  },
  {
    path: REGISTRAR_VOUCHERS_PATH,
    main: VouchersPage,
    exact: true,
  },
  {
    path: REGISTRAR_VOUCHERS_NEW_PATH,
    main: VouchersCreatePage,
    exact: true,
  },
  {
    path: REGISTRAR_VOUCHERS_SHOW_PATH,
    main: VouchersShowPage,
    exact: true,
  },
  {
    path: REGISTRAR_USERS_SHOW_PATH,
    main: UsersShowPage,
  },
];

const facilitatorRoutes = [
  {
    path: FACILITATOR,
    main: FacilitatorDashboard,
    exact: true,
  },
  {
    path: FACILITATOR_DASHBOARD_PATH,
    main: FacilitatorDashboard,
    exact: true,
  },
  {
    path: FACILITATOR_MESSAGES_PATH,
    main: MessagesPage,
  },
  {
    path: FACILITATOR_CURRICULUM_PATH,
    main: FacilitatorCurriculum,
    exact: true,
  },
  {
    path: FACILITATOR_INDIGENOUS_WELCOME_LETTERS_PATH,
    main: IndigenousWelcomeLettersPage,
    exact: true,
  },
  {
    path: FACILITATOR_PARTICIPANTS_SHOW_PATH,
    main: ParticipantsShowPage,
  },
  {
    path: FACILITATOR_COURSE_TYPE_PATH,
    main: FacilitatorCourseTypeShowPage,
    exact: true,
  },
  {
    path: FACILITATOR_COURSE_GLOSSARY_PATH,
    main: FacilitatorCourseGlossaryPage,
    exact: true,
  },
  {
    path: FACILITATOR_COURSE_PHRASEBOOK_PATH,
    main: FacilitatorCoursePhrasebookPage,
    exact: true,
  },
  {
    path: FACILITATOR_COURSE_SEARCH_PATH,
    main: FacilitatorCourseSearchPage,
    exact: true,
  },
  {
    path: FACILITATOR_COURSE_COMPLETION_REPORT_PATH,
    main: FacilitatorCourseComletionReportPage,
    exact: true,
  },
  {
    path: FACILITATOR_DISCUSSION_BOARDS_PATH,
    main: DiscussionBoardsPage,
    exact: true,
  },
  {
    path: FACILITATOR_COMMENTS_PATH,
    main: FacilitatorCommentsShowPage,
    exact: true,
  },
  {
    path: FACILITATOR_COURSE_SELF_CARE_PATH,
    main: FacilitatorCourseSelfCarePage,
  },
  {
    path: FACILITATOR_COURSE_PATH,
    main: FacilitatorCourseShowPage,
  },
  {
    path: FACILITATOR_GROUP_SHOW_PATH,
    main: FacilitatorGroupShowPage,
  },
];

const superFacilitatorRoutes = [
  {
    path: SUPER_FACILITATOR,
    main: SuperFacilitatorDashboard,
    exact: true,
  },
  {
    path: SUPER_FACILITATOR_DASHBOARD_PATH,
    main: SuperFacilitatorDashboard,
    exact: true,
  },
  {
    path: SUPER_FACILITATOR_REPORTS_PATH,
    main: SuperFacilitatorReports,
    exact: true,
  },
  {
    path: SUPER_FACILITATOR_JOURNAL_REPORT_PATH,
    main: SuperFacilitatorJournalReport,
    exact: true,
  },
  {
    path: SUPER_FACILITATOR_DISCUSSION_BOARD_REPORT_PATH,
    main: SuperFacilitatorDiscussionBoardReport,
    exact: true,
  },
];

const curriculumRoutes = [
  {
    path: CURRICULUM_PATH,
    main: CurriculumPage,
    exact: true,
  },
  {
    path: CURRICULUM_COURSE_TYPE_SHOW_PATH,
    main: CourseTypeShowPage,
  },
  {
    path: CURRICULUM_COURSE_EMAIL_TEMPLATES_PATH,
    main: CourseEmailTemplatesPage,
    exact: true,
  },
  {
    path: CURRICULUM_COURSE_GLOSSARY_PATH,
    main: CourseGlossaryPage,
  },
  {
    path: CURRICULUM_COURSE_PHRASEBOOK_PATH,
    main: CoursePhrasebookPage,
  },
  {
    path: CURRICULUM_COURSE_FIND_AND_REPLACE_PATH,
    main: CourseFindAndReplacePage,
    exact: true,
  },
  {
    path: CURRICULUM_COURSE_PROACTIVE_TEMPLATES_PATH,
    main: CourseProactiveTemplatesPage,
    exact: true,
  },
  {
    path: CURRICULUM_COURSE_COMPLETION_REPORT_PATH,
    main: CourseCompletionReportPage,
    exact: true,
  },
  {
    path: CURRICULUM_COURSE_SELF_CARE_PATH,
    main: CourseSelfCarePage,
  },
  {
    path: CURRICULUM_COURSE_SHOW_PATH,
    main: CourseShowPage,
  },
];

const reportingRoutes = [
  {
    path: REPORTING,
    main: ReportingDashboard,
    exact: true,
  },
  {
    path: REPORTING_HARM_REPORT,
    main: HarmPreventionReportPage,
    exact: true,
  },
];

export default [
  ...registrarRoutes,
  ...facilitatorRoutes,
  ...superFacilitatorRoutes,
  ...curriculumRoutes,
  ...reportingRoutes,
];
