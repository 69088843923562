import { takeLatest, put, select, call } from 'redux-saga/effects';
import { fetchGet, fetchPost, handleSimpleFetchError, fetchPatch, fetchDelete } from 'lib/apiHelpers';
import { GET_CURRENT_USER } from 'store/actions';
import { LOAD_FACILITATOR_COURSE_TYPES } from 'store/courseTypes/actions';
import { CREATE_COMMENT, CREATE_REPLY_COMMENT, LOAD_COMMENTS, LOAD_PARTICIPANT, LOAD_PARTICIPANT_IWL_STATUS, UPDATE_COMMENT, MARK_NOTIFICATION_AS_READ, MARK_NOTIFICATION_AS_UNREAD, DELETE_COMMENT, UPDATE_COMMENT_FLAG, CREATE_FACILITATOR_NOTE, UPDATE_FACILITATOR_NOTE } from 'store/facilitatorDashboard/actions';
import { SET_NOTIFICATION } from 'store/flashNotifications/actions';

export default function* sagas() {
  yield takeLatest(GET_CURRENT_USER.REQUEST, function* getCurrentUser() {
    const state = yield select();
    if (!state.currentUser) {
      try {
        const { data } = yield fetchGet('/api/user', {});
        yield put(GET_CURRENT_USER.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, GET_CURRENT_USER);
      }
    } else {
      yield put(GET_CURRENT_USER.success({}, { cached: true }));
    }
  });

  yield takeLatest(LOAD_FACILITATOR_COURSE_TYPES.REQUEST, function* loadCourseTypes() {
    const state = yield select();
    if (!state.courseTypes.allIds.length) {
      try {
        const { data } = yield fetchGet('/api/facilitator/course_types', {});
        yield put(LOAD_FACILITATOR_COURSE_TYPES.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_FACILITATOR_COURSE_TYPES);
      }
    } else {
      yield put(LOAD_FACILITATOR_COURSE_TYPES.success({}, { cached: true }));
    }
  });

  yield takeLatest(LOAD_COMMENTS.REQUEST, function* loadComments({ payload }) {
    const { id, subGroupId, discussionTaskId } = payload;
    try {
      const { data } = yield fetchGet('/api/facilitator/discussion_tasks/comments', { groupId: id, subGroupId, taskId: discussionTaskId });
      yield put(LOAD_COMMENTS.success(data, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_COMMENTS);
    }
  });

  yield takeLatest(CREATE_REPLY_COMMENT.REQUEST, function* saveComment({ payload }) {
    try {
      const { comment } = yield fetchPost('/api/facilitator/comments/', payload);
      yield put(CREATE_REPLY_COMMENT.success({ comment }, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_REPLY_COMMENT);
    }
  });

  yield takeLatest(CREATE_COMMENT.REQUEST, function* saveComment({ payload }) {
    try {
      const comment = yield fetchPost('/api/facilitator/comments/', payload);
      yield put(CREATE_COMMENT.success(comment, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, CREATE_COMMENT);
    }
  });

  yield takeLatest(UPDATE_COMMENT.REQUEST, function* updateComment({ payload }) {
    try {
      const comment = yield fetchPatch(`/api/facilitator/comments/${payload.updatedComment.id}`, payload.updatedComment);
      yield put(UPDATE_COMMENT.success(comment, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT);
    }
  });

  yield takeLatest(MARK_NOTIFICATION_AS_READ.REQUEST, function* updateComment({ payload }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/notifications/${payload.id}/mark_as_read`, payload);
      if (data.isRead) {
        yield put(MARK_NOTIFICATION_AS_READ.success(data, { cached: false }));
      } else {
        yield handleSimpleFetchError('Notification could not be cleared', UPDATE_COMMENT);
      }
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT);
    }
  });

  yield takeLatest(MARK_NOTIFICATION_AS_UNREAD.REQUEST, function* updateComment({ payload }) {
    try {
      const { data } = yield fetchPatch('/api/facilitator/notifications/mark_as_unread', payload);
      yield put(MARK_NOTIFICATION_AS_UNREAD.success({ data }, { cached: false }));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT);
    }
  });

  yield takeLatest(LOAD_PARTICIPANT.REQUEST, function* loadParticipant({ payload }) {
    const { facilitatorDashboard } = yield select();
    if (facilitatorDashboard.participants.allIds.includes(payload)) {
      yield put(LOAD_PARTICIPANT.success({}, { cached: true }));
    } else {
      try {
        const { data } = yield fetchGet(`/api/facilitator/group_memberships/${payload}`, { participantOnly: true });
        yield put(LOAD_PARTICIPANT.success(data, { cached: false }));

        if (data.isIndigenous) {
          yield put(LOAD_PARTICIPANT_IWL_STATUS.request({ id: data.id, userId: data.userId }));
        }
      } catch (err) {
        yield handleSimpleFetchError(err, LOAD_PARTICIPANT, { id: payload });
      }
    }
  });

  yield takeLatest(LOAD_PARTICIPANT_IWL_STATUS.REQUEST, function* getIWLStatus({ payload }) {
    try {
      const { data } = yield fetchGet(`/api/users/${payload.userId}/group_memberships/${payload.id}/iwl_status`);
      yield put(LOAD_PARTICIPANT_IWL_STATUS.success(data));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_PARTICIPANT_IWL_STATUS);
    }
  });

  yield takeLatest(DELETE_COMMENT.REQUEST, function* deleteComment({ payload }) {
    try {
      const { commentId } = yield fetchDelete(`/api/facilitator/comments/${payload.id}`, payload);
      yield put(DELETE_COMMENT.success(commentId));
    } catch (err) {
      yield handleSimpleFetchError(err, DELETE_COMMENT);
    }
  });

  yield takeLatest(UPDATE_COMMENT_FLAG.REQUEST, function* updateCommentFlag({ payload }) {
    try {
      const comment = yield fetchPatch(`/api/facilitator/comments/${payload.id}/flag`, payload);
      yield put(UPDATE_COMMENT.success(comment));
    } catch (err) {
      yield handleSimpleFetchError(err, UPDATE_COMMENT_FLAG);
    }
  });

  yield takeLatest(CREATE_FACILITATOR_NOTE.REQUEST, function* createNote({ payload, meta }) {
    try {
      const { data } = yield fetchPost('/api/facilitator/facilitator_notes', payload);
      yield put(CREATE_FACILITATOR_NOTE.success(data, { cached: false }));
    } catch (err) {
      yield put(SET_NOTIFICATION.action({
        message: 'There was an error creating the note.',
        type: 'error',
      }));
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });

  yield takeLatest(UPDATE_FACILITATOR_NOTE.REQUEST, function* updateNote({ payload, meta }) {
    try {
      const { data } = yield fetchPatch(`/api/facilitator/facilitator_notes/${payload.id}`, payload);
      yield put(UPDATE_FACILITATOR_NOTE.success(data, { cached: false }));
    } catch (err) {
      yield put(SET_NOTIFICATION.action({
        message: 'There was an error saving the note.',
        type: 'error',
      }));
    } finally {
      yield call(meta.formikActions.setSubmitting, false);
    }
  });
}
