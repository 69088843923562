import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { SIZES } from 'lib/constants';
import SanyasIcon from 'components/shared/SanyasIcon';
import { iconNames } from 'components/shared/SanyasIcon/icons';

const DEFAULT_BUTTON_TEXT = {
  Copy: 'Copy',
  Copied: 'Copied!',
};

function CopyButton({ result, variant, size, className, disabled, icon, buttonText, delay }) {
  const COPY = { ...DEFAULT_BUTTON_TEXT, ...buttonText };
  const [copyText, setCopyText] = useState(COPY.Copy);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(result);
    setCopyText(COPY.Copied);
  }, [result, COPY.Copied]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopyText(COPY.Copy);
    }, delay);

    return () => clearTimeout(timer);
  }, [copyText, COPY.Copy, delay]);

  return (
    <Button variant={variant} size={size} className={className} disabled={!result || disabled} onClick={handleCopy}>
      <SanyasIcon name={icon} className="me-1" />
      {copyText}
    </Button>
  );
}

CopyButton.defaultProps = {
  result: null,
  variant: 'primary',
  size: 'md',
  className: null,
  disabled: false,
  icon: 'copy',
  buttonText: {},
  delay: 1200,
};

CopyButton.propTypes = {
  result: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(iconNames),
  buttonText: PropTypes.shape({
    Copy: PropTypes.string,
    Copied: PropTypes.string,
  }),
  delay: PropTypes.number,
};

export default CopyButton;
