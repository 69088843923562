import { asyncAction, syncAction } from 'lib/actionCreatorCreators';

export const LOAD_USER = asyncAction('LOAD_USER');
export const UPDATE_USER = asyncAction('UPDATE_USER');
export const DELETE_USER = asyncAction('DELETE_USER');
export const RESET_USER = syncAction('RESET_USER');
export const LOAD_EMAIL_CONFIRMATIONS = asyncAction('LOAD_EMAIL_CONFIRMATIONS');
export const RESET_LOGIN = asyncAction('RESET_LOGIN');
export const UPDATE_USER_ACTIVATION = asyncAction('UPDATE_USER_ACTIVATION');
export const RESET_USER_STATUS = syncAction('RESET_USER_STATUS');
export const RESEND_EMAIL_CONFIRMATION = syncAction('RESEND_EMAIL_CONFIRMATION');
export const UPDATE_MARKED_AS_INDIGENOUS = asyncAction('UPDATE_MARKED_AS_INDIGENOUS');
