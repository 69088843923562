import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import uaParser from 'ua-parser-js';
import parseISO from 'date-fns/parseISO';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { ACTION_STATUSES, REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import { REGISTRAR_USERS_EMAILS_PATH, REGISTRAR_USERS_GROUP_MEMBERSHIPS_PATH, REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH, REGISTRAR_USERS_SHOW_PATH } from 'lib/routerPaths';
import { LOAD_USER, RESET_USER } from 'store/user/actions';
import { LOAD_GROUP_MEMBERSHIPS, RESET_GROUP_MEMBERSHIPS } from 'store/groupMemberships/actions';
import { RESET_LETTER_MESSAGE } from 'store/indigenousWelcomeLetters/actions';
import GroupMembershipsTabPage from 'pages/users/GroupMembershipsTabPage';
import ProfileTabPage from 'pages/users/ProfileTabPage';
import EmailsTabPage from 'pages/users/EmailsTabPage';
import RBSpinner from 'react-bootstrap/Spinner';
import RegistrarTemplate from 'components/templates/RegistrarTemplate';
import TabbedNav from 'components/shared/Navigation/TabbedNav';
import Breadcrumbs from 'components/shared/Breadcrumbs';
import Spinner from 'components/shared/Spinner';
import SanyasIcon from 'components/shared/SanyasIcon';
import ParticipantBadge from 'components/shared/Badge/ParticipantBadge';

function UsersShowPage() {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const groupMemberships = useSelector((state) => state.groupMemberships);
  const dispatch = useDispatch();

  const peoplePath = '/registrar/people';
  const latestGroupMembership = groupMemberships.data?.find((gm) => [...ACTION_STATUSES, 'completed'].includes(gm.completion)) ?? groupMemberships.data?.[0];
  const { browser, os } = !!latestGroupMembership?.userAgent && uaParser(latestGroupMembership.userAgent);

  useEffect(() => {
    dispatch(LOAD_USER.request(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (user.data?.id && !groupMemberships.data && groupMemberships.status !== REDUX_STATUS.PENDING) {
      dispatch(LOAD_GROUP_MEMBERSHIPS.request({ id: user.data.id }));
    }
  }, [groupMemberships, user.data?.id, dispatch]);

  useEffect(() => {
    if (user.success === REDUX_SUCCESS.DELETED) {
      window.location.replace(peoplePath);
    }
  }, [user.success]);

  useEffect(() => () => {
    dispatch(RESET_USER.action());
    dispatch(RESET_GROUP_MEMBERSHIPS.action());
    dispatch(RESET_LETTER_MESSAGE.action());
  }, [dispatch]);

  return (
    <RegistrarTemplate>
      <div className="d-flex flex-column flush">
        <div className="header pb-0">
          <div className="d-flex align-items-center flex-col-gap-sm">
            <SanyasIcon name="person" size="3x" />
            <div>
              <Breadcrumbs
                navItems={[
                  { label: 'People', href: peoplePath },
                  { label: user.data?.fullName ?? '...' },
                ]}
              />

              <div className="d-flex align-items-center mb-2">
                <h1 className="mb-0">
                  { user.data?.fullName ?? '...' }
                </h1>

                {(user.status === REDUX_STATUS.PENDING || groupMemberships.status === REDUX_STATUS.PENDING) && (
                  <RBSpinner variant="primary" size="sm" className="ms-1" animation="border" role="status" />
                )}
                <span className="ms-2 text-small">
                  <ParticipantBadge
                    state={latestGroupMembership?.completion}
                    completion={latestGroupMembership?.decoratedCompletion}
                    activation={user.data?.activation}
                  />
                  <span className="ms-2">
                    {!!latestGroupMembership?.lastSeenAt && `Last seen ${formatDistanceToNow(parseISO(latestGroupMembership.lastSeenAt))} ago |`}
                    {(browser && os) && ` ${browser.name} ${browser.version}, ${os.name} ${os.version}`}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="position-relative">
            <TabbedNav
              variant="underline"
              navItems={[
                {
                  title: 'Profile',
                  href: `${peoplePath}/${id}`,
                  match: {
                    path: REGISTRAR_USERS_SHOW_PATH,
                    exact: true,
                  },
                },
                {
                  title: 'Groups',
                  href: `${peoplePath}/${id}/group_memberships`,
                  match: {
                    path: [
                      REGISTRAR_USERS_GROUP_MEMBERSHIPS_PATH,
                      REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH,
                    ],
                  },
                },
                {
                  title: 'Emails',
                  href: `${peoplePath}/${id}/emails`,
                  match: {
                    path: REGISTRAR_USERS_EMAILS_PATH,
                  },
                },
              ]}
            />

          </div>
        </div>

        <Switch>
          <Route path={[
            REGISTRAR_USERS_GROUP_MEMBERSHIPS_PATH,
            REGISTRAR_USERS_GROUP_MEMBERSHIP_PATH,
          ]}
          >
            <GroupMembershipsTabPage user={user.data} groupMemberships={groupMemberships} />
          </Route>
          <Route path={REGISTRAR_USERS_EMAILS_PATH}>
            <EmailsTabPage user={user.data} />
          </Route>
          <Route path="*">
            {(!user.data && user.status === REDUX_STATUS.PENDING) ? <div className="m-4"><Spinner /></div> : <ProfileTabPage user={user} hasGroupMembership={groupMemberships.data?.length > 0} />}
          </Route>
        </Switch>
      </div>
    </RegistrarTemplate>
  );
}

export default UsersShowPage;
