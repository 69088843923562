import React from 'react';
import { useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import { FULL_MONTH_ORDINAL_DAY } from 'lib/dateFormats';
import { titleCase } from 'lib/utils';
import Badge from 'components/shared/Badge/Badge';

const STATUS_BADGE_VARIANTS = {
  in_session: 'green',
  in_extension: 'blue',
  default: 'grey',
};

const extension = (group) => {
  if (!group || group.state === 'closed') return {};

  let date = null;
  let formattedDate = null;
  let text = null;

  if (group.hasExtension || group.state === 'in_session') {
    date = group.hasExtension ? group.extendedUntil : group.closeSessionAt;
    formattedDate = format(parseISO(date), FULL_MONTH_ORDINAL_DAY);
    text = (group.hasExtension && group.state === 'in_session') ? 'Extension scheduled for' : 'until';
  }

  return ({ formattedDate, text });
};

function StatusLabel() {
  const { group } = useSelector((state) => state.groupShow);
  const { formattedDate, text } = extension(group);

  if (!group) return null;

  return (
    <span className="status text-small">
      <Badge
        variant={STATUS_BADGE_VARIANTS[group.state] || STATUS_BADGE_VARIANTS.default}
        title={titleCase(group.state)}
      />

      <span className="ms-2">
        {text}
        <span className="ms-1">{formattedDate}</span>
      </span>
    </span>
  );
}

export default StatusLabel;
