import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from './FieldWrapper';
import TinyMce from './TinyMce';
import Simple from './TinyMce/Simple';
import Minimal from './TinyMce/Minimal';

function RichTextField(props) {
  const { id, name, label, disabled, helpText, infoText, hideLabel, variant, onFieldChange, onFieldBlur, className, options } = props;

  return (
    <FieldWrapper
      id={id}
      name={name}
      label={label}
      helpText={helpText}
      infoText={infoText}
      hideLabel={hideLabel}
      onFieldChange={onFieldChange}
      onFieldBlur={onFieldBlur}
      className={className}
    >
      {variant === 'minimal' && <Minimal name={name} disabled={disabled} options={options} />}
      {variant === 'simple' && <Simple name={name} disabled={disabled} options={options} />}
      {variant === 'full' && <TinyMce name={name} disabled={disabled} options={options} />}
    </FieldWrapper>
  );
}

RichTextField.defaultProps = {
  id: null,
  label: null,
  disabled: false,
  helpText: null,
  infoText: null,
  hideLabel: false,
  variant: 'full',
  onFieldChange: null,
  onFieldBlur: null,
  className: null,
  options: {},
};

RichTextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  helpText: PropTypes.string,
  infoText: PropTypes.string,
  hideLabel: PropTypes.bool,
  variant: PropTypes.oneOf(['minimal', 'simple', 'full']),
  onFieldChange: PropTypes.func,
  onFieldBlur: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.shape({}),
};

export default RichTextField;
