import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LOAD_COMMENTS } from 'store/facilitatorDashboard/actions';
import { selectNoteBySubgroupAndDiscussionTask } from 'store/facilitatorDashboard/selectors';
import ErrorMessage from 'components/shared/ErrorMessage';
import FacilitatorTemplate from 'components/templates/FacilitatorTemplate';
import CommentsHeader from 'components/facilitator/DiscussionBoards/CommentsHeader';
import Spinner from 'components/shared/Spinner';
import Comment from 'components/shared/Comments/Comment';
import Conversation from 'components/shared/Comments/Conversation';
import NotesPanel from 'components/facilitator/NotesPanel';

function CommentsShowPage() {
  const params = useParams();
  const { error, loadingComments, commentIds } = useSelector((state) => state.facilitatorDashboard);
  const note = useSelector((state) => selectNoteBySubgroupAndDiscussionTask(state, { subGroupId: params.subGroupId, taskId: params.discussionTaskId }));
  const dispatch = useDispatch();

  const [showNotesPanel, setShowNotesPanel] = useState(false);
  const handleClose = () => setShowNotesPanel(false);
  const toggleShow = () => setShowNotesPanel((s) => !s);

  useEffect(() => {
    dispatch(LOAD_COMMENTS.request(params));
  }, [dispatch, params]);

  const commentCards = useMemo(
    () => commentIds?.map((commentId) => (
      <div key={commentId} className="col-md-8 mx-auto d-flex flex-column align-items-end">
        <Conversation commentId={commentId} />
      </div>
    )),
    [commentIds],
  );

  return (
    <FacilitatorTemplate className="bg-light">
      <ErrorMessage error={{ message: error }} />
      <CommentsHeader className="mb-4" toggleNotes={toggleShow} />

      <NotesPanel note={note} show={showNotesPanel} onHide={handleClose} />

      {loadingComments && (
        <div className="h-50">
          <Spinner size="3x" className="ms-1" animation="border" role="status" />
        </div>
      )}

      {(!loadingComments && commentIds) && (
        <>
          {commentCards}
          <div className="col-md-8 mx-auto d-flex flex-column align-items-end">
            <Comment discussionTaskId={params.discussionTaskId} subGroupId={params.subGroupId} />
          </div>
        </>
      )}
    </FacilitatorTemplate>
  );
}

export default CommentsShowPage;
