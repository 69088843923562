import React from 'react';
import { useSelector } from 'react-redux';
import { LEGACY_APP_DOMAIN } from 'config';
import { GroupMembershipPropType } from 'lib/propTypes';
import { titleCase } from 'lib/utils';
import { selectJournalDataByParticipantId } from 'store/groupShow/selectors';
import SimpleToolTip from 'components/shared/SimpleToolTip/SimpleToolTip';
import JournalDot from './JournalDot';

const tooltipText = (journalData) => {
  if (!journalData) return null;

  let text = '';

  if (journalData.primaryJournalNotificationCount > 0) {
    text = 'Primary journal answer is unread';
  }

  if (journalData.secondaryJournalNotificationCount > 0) {
    if (text) text += ', and ';
    text += `${journalData.secondaryJournalNotificationCount} unread secondary journal comment${journalData.secondaryJournalNotificationCount > 1 ? 's' : ''}`;
  }

  return (
    <>
      <br />
      {text}
    </>
  );
};

function JournalDetails({ gm }) {
  const journalData = useSelector((state) => selectJournalDataByParticipantId(state, gm.id));

  return (
    <li className="py-2 px-3">
      <SimpleToolTip
        text={(
          <span className="text-small">
            {titleCase(gm.completion)}
            {tooltipText(journalData)}
          </span>
      )}
        placement="top"
        width={240}
        innerClassName="d-flex align-items-center"
        innerStyle={{ width: 'fit-content' }}
      >
        <a
          href={`${LEGACY_APP_DOMAIN}/group_memberships/${gm.id}/journal_tasks`}
          target="_blank"
          rel="noreferrer"
          className="text-decoration-none"
        >
          {`${gm.firstName} ${gm.lastName}`}
        </a>

        <JournalDot data={journalData} className="ms-2" primary />
        <JournalDot data={journalData} className="ms-2" />
      </SimpleToolTip>
    </li>
  );
}

JournalDetails.propTypes = {
  gm: GroupMembershipPropType.isRequired,
};

export default JournalDetails;
