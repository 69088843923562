import React from 'react';
import { useSelector } from 'react-redux';
import { TaskPropType } from 'lib/propTypes';
import { selectSubgroupsValues } from 'store/groupShow/selectors';
import SubGroupDetail from './SubGroupDetail';

function TaskDetail({ task }) {
  const subGroups = useSelector((state) => selectSubgroupsValues(state));

  return (
    <>
      <strong>{task.name}</strong>

      { subGroups.map((subGroup) => (
        <SubGroupDetail
          key={subGroup.id}
          subGroup={subGroup}
          discussionTaskId={task.id}
          count={task.subGroups[subGroup.id].count}
        />
      ))}
    </>
  );
}

TaskDetail.propTypes = {
  task: TaskPropType.isRequired,
};

export default TaskDetail;
