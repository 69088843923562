import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CoursePropType } from 'lib/propTypes';
import { FACILITATOR_COURSE_GLOSSARY_PATH, FACILITATOR_COURSE_PHRASEBOOK_PATH, FACILITATOR_COURSE_SEARCH_PATH, FACILITATOR_COURSE_SELF_CARE_PATH, FACILITATOR_COURSE_COMPLETION_REPORT_PATH } from 'lib/routerPaths';
import { buildRoutePath } from 'lib/routerHelpers';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import Dropdown from 'react-bootstrap/Dropdown';
import SanyasIcon from 'components/shared/SanyasIcon';

function CourseDropDown({ course }) {
  const courseType = useSelector((state) => selectCourseTypeById(state, course?.courseTypeId));

  if (!course) return null;

  return (
    <Dropdown className="ms-auto">
      <Dropdown.Toggle variant={null} bsPrefix="no-arrow" className="py-0">
        <SanyasIcon name="kebabVertical" size="lg" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to={buildRoutePath(FACILITATOR_COURSE_SEARCH_PATH, { courseId: course?.id })}>
          Search
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={buildRoutePath(FACILITATOR_COURSE_GLOSSARY_PATH, { courseId: course?.id })}>
          Glossary
        </Dropdown.Item>
        <Dropdown.Item as={Link} to={buildRoutePath(FACILITATOR_COURSE_PHRASEBOOK_PATH, { courseId: course?.id })}>
          Phrasebook
        </Dropdown.Item>
        {courseType?.showSelfCareTab && (
          <Dropdown.Item as={Link} to={buildRoutePath(FACILITATOR_COURSE_SELF_CARE_PATH, { courseId: course?.id })}>
            Self Care
          </Dropdown.Item>
        )}
        <Dropdown.Item as={Link} to={buildRoutePath(FACILITATOR_COURSE_COMPLETION_REPORT_PATH, { courseId: course?.id })}>
          Completion Report
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

CourseDropDown.defaultProps = {
  course: null,
};

CourseDropDown.propTypes = {
  course: CoursePropType,
};

export default CourseDropDown;
