import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { LEGACY_APP_DOMAIN } from 'config';
import { LANGUAGE_PREFERENCE_OPTIONS, LOCALE_VALUES, PROVINCES } from 'lib/constants';
import { UserPropType } from 'lib/propTypes';
import { copyResendConfirmationLink, nullToString, stringToNull } from 'lib/utils';
import { RESEND_EMAIL_CONFIRMATION, UPDATE_USER } from 'store/user/actions';
import { Button, Spinner } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import InputField from 'components/shared/FormFields/InputField';
import SelectField from 'components/shared/FormFields/SelectField';
import LoginField from 'components/shared/LoginField';
import SanyasIcon from 'components/shared/SanyasIcon';
import CopyButton from 'components/shared/Button/CopyButton';
import Confirmation from 'components/shared/Modal/Confirmation';
import Activation from 'components/users/Profile/Activation';
import DeleteUser from 'components/users/Profile/DeleteUser';

const FormSchema = Yup.object().shape({
  login: Yup.string().required('Display name is required').test(
    'login',
    'Display name is required',
    (value) => value?.trim().length > 0,
  ),
  firstName: Yup.string()
    .required('First name is required')
    .test(
      'commas-quotes',
      'First name cannot include commas or double quotes',
      (value) => !value?.includes(',') && !value?.includes('"'),
    ),
  lastName: Yup.string()
    .required('Last name is required')
    .test(
      'commas-quotes',
      'Last name cannot include commas or double quotes',
      (value) => !value?.includes(',') && !value?.includes('"'),
    ),
  email: Yup.string().email('Email address is not valid').required('Email address is required'),
  phone: Yup.string().nullable(),
  languagePreference: Yup.string().oneOf(LOCALE_VALUES).typeError('Language preference is not valid'),
  address1: Yup.string().nullable(),
  address2: Yup.string().nullable(),
  city: Yup.string().nullable(),
  province: Yup.string().oneOf(PROVINCES.map((p) => p.value), 'Province/territory is not valid').typeError('Province/territory is not valid'),
  country: Yup.string(),
  postalCode: Yup.string().nullable(),
});

function ProfileTabPage({ user, hasGroupMembership }) {
  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const [showResendEmailConfirmationModal, setShowResendEmailConfirmationModal] = useState(false);

  // ensure null is cast to empty string
  const initialValues = useMemo(() => nullToString(user?.data), [user?.data]);

  const handleSubmit = useCallback((values, actions) => {
    // ensure empty string is cast to null
    dispatch(UPDATE_USER.request(stringToNull(values), { formikActions: actions }));
  }, [dispatch]);

  return (
    <div className="my-4 px-4">
      <ErrorMessage error={user?.error} className="mt-2" />

      <div className="row">
        <div className="col-md-8 col-xl-9">
          <Formik
            initialValues={initialValues}
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ dirty, isSubmitting }) => (
              <Form>
                <fieldset className="mb-4">
                  {/* Hidden legend for accessibility */}
                  <legend className="sr-only">Overview</legend>
                  <div className="d-flex align-items-center">
                    {/* Visible title, aligned with button */}
                    <p className="mb-0 fs-4 fw-semibold" aria-hidden>Overview</p>
                    <Button type="submit" className="ms-auto" disabled={!dirty || isSubmitting}>
                      Save
                      {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
                    </Button>
                  </div>

                  <div className="row">
                    <InputField className="col-sm-6" name="firstName" />

                    <InputField className="col-sm-6" name="lastName" />
                  </div>

                  <div className="row">
                    <SelectField
                      className="col-sm-6"
                      name="languagePreference"
                      label="Preferred language"
                      options={LANGUAGE_PREFERENCE_OPTIONS}
                      includeBlank="Select language preference"
                    />
                  </div>

                  <div className="row">
                    <LoginField
                      className="col-sm-6"
                      formLabel
                      label="Display name"
                      value={initialValues.login}
                      loginId={initialValues.id}
                    />
                  </div>
                </fieldset>

                <fieldset className="mb-4">
                  <legend className="fs-4 fw-semibold">Contact</legend>

                  <div className="row">

                    <InputField className="col-sm-6" name="email" type="email" />

                    <InputField className="col-sm-6" name="phone" label="Phone number" type="tel" />

                    <InputField className="col-sm-6" name="address1" label="Address (line 1)" />

                    <InputField className="col-sm-6" name="address2" label="Address (line 2)" />

                    <InputField className="col-sm-6" name="city" />

                    <SelectField
                      className="col-sm-6"
                      name="province"
                      label="Province or territory"
                      options={PROVINCES}
                      includeBlank="Select province/territory"
                    />

                    <InputField className="col-sm-6" name="country" />

                    <InputField className="col-sm-6" name="postalCode" />
                  </div>
                </fieldset>
              </Form>
            )}
          </Formik>
        </div>

        {currentUser?.hasFacilitatorAccess && (
          <div className="col">
            <div className="mt-3 mt-md-0 mb-3 border rounded px-3 py-2">
              <h4 className="py-2">Actions</h4>

              <ul className="list-unstyled">
                {hasGroupMembership && (
                  <li className="d-flex align-items-baseline mb-3 text-primary">
                    <SanyasIcon name="boxArrowLeft" className="me-2" />
                    <a
                      className="btn-plain"
                      href={`${LEGACY_APP_DOMAIN}/user_impersonations/${user.data?.id}/create`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Impersonate
                    </a>
                  </li>
                )}

                {(currentUser?.isRegistrar && user.data?.canResendEmailConfirmation) && (
                  <li className="d-flex align-items-baseline mb-3 text-primary">
                    <SanyasIcon name="envelope" className="me-2" />
                    <Button variant="link" className="d-block btn-plain p-0 text-start" onClick={() => setShowResendEmailConfirmationModal(true)}>
                      Resend email confirmation
                    </Button>
                  </li>
                )}

                {(currentUser?.isRegistrar && user.data?.activation) && (
                  <li className="d-flex align-items-baseline mb-3 text-primary">
                    <Activation user={user.data} />
                  </li>
                )}

                {(currentUser?.isRegistrar && user.data?.isParticipant && !hasGroupMembership) && (
                  <li className="d-flex align-items-baseline mb-3 text-primary">
                    <DeleteUser user={user.data} />
                  </li>
                )}

                {(!currentUser?.isRegistrar && !hasGroupMembership) && (
                  <li className="text-muted">
                    No actions available
                  </li>
                )}
              </ul>

              <Confirmation
                open={showResendEmailConfirmationModal}
                close={() => setShowResendEmailConfirmationModal(false)}
                title="Resend email confirmation"
                text={(
                  <>
                    <p>Are you sure you want to resend the email confirmation?</p>
                    <p>
                      You can also use a link to manually send it to a registrant.
                      <br />
                      <CopyButton
                        variant={null}
                        className="btn-plain px-0"
                        icon="link45"
                        buttonText={{ Copy: 'Copy link' }}
                        result={copyResendConfirmationLink({
                          userId: user.data?.id,
                          permalink: user.data?.initialRegistrationAttrs?.organizationPermalink,
                          token: user.data?.initialRegistrationAttrs?.emailConfirmationToken,
                        })}
                      />
                    </p>
                  </>
                )}
                confirmText="Send"
                confirmAction={() => {
                  dispatch(RESEND_EMAIL_CONFIRMATION.action({ id: user.data.id }));
                }}
                cancelText={false}
                delay={400}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ProfileTabPage.propTypes = {
  user: UserPropType.isRequired,
  hasGroupMembership: PropTypes.bool.isRequired,
};

export default ProfileTabPage;
