import React from 'react';
import PropTypes from 'prop-types';

function WordCount({ text, maxLength, className }) {
  const words = text.split(/\s/).filter((word) => word !== '');

  return (
    <span className={className}>
      {`${maxLength - words.length} words remaining`}
    </span>
  );
}

WordCount.defaultProps = {
  text: '',
  maxLength: 250,
  className: '',
};

WordCount.propTypes = {
  text: PropTypes.string,
  maxLength: PropTypes.number,
  className: PropTypes.string,
};

export default WordCount;
