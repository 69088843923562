import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RefPropType } from 'lib/propTypes';

function Textarea({ value, inputRef, ...props }) {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const textAreaRef = inputRef || ref;

  const autoResize = useCallback(() => {
    const { borderTopWidth, borderBottomWidth } = window.getComputedStyle(textAreaRef.current);
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight + parseInt(borderTopWidth, 10) + parseInt(borderBottomWidth, 10)}px`;
  }, [textAreaRef]);

  useEffect(() => {
    // Resize textarea when value or isVisible changes
    autoResize();
  }, [textAreaRef, value, isVisible, autoResize]);

  useEffect(() => {
    // Set isVisible when textarea becomes visible
    const textarea = textAreaRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    });

    if (textarea) observer.observe(textarea);

    return () => {
      if (textarea) observer.unobserve(textarea);
    };
  }, [textAreaRef]);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <textarea ref={textAreaRef} value={value} spellCheck {...props} />
  );
}

Textarea.defaultProps = {
  value: '',
  inputRef: null,
};

Textarea.propTypes = {
  value: PropTypes.string,
  inputRef: RefPropType,
};

export default Textarea;
