import React from 'react';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import TextareaElement from 'components/shared/FormFields/Elements/Textarea';

function Textarea(props) {
  const { id, value, touched, error, handleChange, handleBlur } = useFieldContext();

  return (
    <TextareaElement
      id={id}
      className={`form-control ${(touched && error) ? 'is-invalid' : ''}`}
      value={value || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

export default Textarea;
