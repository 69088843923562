import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useFormikContext } from 'formik';
import { Spinner } from 'react-bootstrap';

function AutoSave({ debounceMs, spinner }) {
  const [lastSaved, setLastSaved] = useState(null);
  const formik = useFormikContext();

  // NOTE: warning, maybe change to useDebounce https://usehooks.com/useDebounce/
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    _debounce(() => {
      if (formik.isValid) {
        formik.submitForm().then(() => setLastSaved(new Date()));
      }
    }, debounceMs),
    [debounceMs, formik.submitForm, formik.isValid],
  );

  useEffect(() => {
    debouncedSubmit();

    return debouncedSubmit.cancel;
  }, [debouncedSubmit, formik.values]);

  return (
    <>
      {(spinner && formik.isSubmitting) && <Spinner variant="primary" size="sm" className="ms-1" animation="border" role="status" />}

      {(!spinner && formik.isSubmitting) && <small className="text-muted">Updating&hellip;</small>}

      {(!spinner && !formik.isSubmitting && lastSaved !== null) && (
        <small className="text-muted">
          {`Updated ${formatDistanceToNow(lastSaved, { addSuffix: true })}`}
        </small>
      )}
    </>
  );
}

AutoSave.defaultProps = {
  spinner: false,
};

AutoSave.propTypes = {
  debounceMs: PropTypes.number.isRequired,
  spinner: PropTypes.bool,
};

export default AutoSave;
