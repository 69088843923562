import React from 'react';
import { useSelector } from 'react-redux';
import { GROUP_MEMBERSHIP_ACTION_OPTIONS } from 'lib/constants';
import { GroupMembershipPropType } from 'lib/propTypes';
import { Dropdown } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import GroupMembershipActionsMenu from 'components/users/GroupMembershipActionsMenu';

function ParticipantActions({ participant }) {
  const group = useSelector((state) => state.groupShow.group);

  return (
    <Dropdown>
      <Dropdown.Toggle variant={null} bsPrefix="no-arrow" size="sm" id={`group-membership-actions-menu-${participant.id}`}>
        <SanyasIcon name="kebabVertical" size="lg" />
      </Dropdown.Toggle>

      <GroupMembershipActionsMenu
        groupMembership={participant}
        group={group}
        options={[
          GROUP_MEMBERSHIP_ACTION_OPTIONS.WELCOME,
          GROUP_MEMBERSHIP_ACTION_OPTIONS.CONFIRMATION,
          GROUP_MEMBERSHIP_ACTION_OPTIONS.CERTIFICATE,
          // GROUP_MEMBERSHIP_ACTION_OPTIONS.PARE,
          GROUP_MEMBERSHIP_ACTION_OPTIONS.IMPERSONATE,
        ]}
      />
    </Dropdown>
  );
}

ParticipantActions.propTypes = {
  participant: GroupMembershipPropType.isRequired,
};

export default ParticipantActions;
