import React from 'react';
import PropTypes from 'prop-types';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { Editor } from '@tinymce/tinymce-react';

import 'tinymce/models/dom';

// Editor styles
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/icons/default';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import { init } from 'lib/tinyMce/utils';

function TinyMce({ disabled, options }) {
  const { id, value, handleChange } = useFieldContext();

  return (
    <Editor
      id={id}
      value={value}
      onEditorChange={handleChange}
      disabled={disabled}
      init={{
        ...init,
        ...options,
        plugins: [
          'advlist', 'autolink', 'autoresize', 'fullscreen', 'link', 'lists',
        ],
        menubar: false,
        toolbar: 'bold italic strikethrough | bullist numlist outdent indent | blocks align | link undo redo fullscreen',
        content_style: [contentUiCss, 'body { padding-inline: 0.5rem }'].join('\n'),
      }}
    />
  );
}

TinyMce.defaultProps = {
  disabled: false,
  options: {},
};

TinyMce.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.shape({}),
};

export default TinyMce;
