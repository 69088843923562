import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { selectGroupedTags } from 'store/emailTemplates/selectors';
import SanyasIcon from '../SanyasIcon';
import EmailTemplateBadge from './Badge';
import './styles.scss';

const TAG_GROUPS = {
  column1: {
    Course: 'book',
    Participant: 'person',
    Other: 'eggFried',
  },
  column2: {
    Group: 'people',
    Links: 'link45',
  },
  column3: {
    IWL: 'flower',
  },
};

function Shortcodes({ addTemplateTag }) {
  const groupedTags = useSelector((state) => selectGroupedTags(state));
  const [showTags, setShowTags] = useState(false);

  const toggleTags = useCallback(() => {
    setShowTags((prev) => !prev);
  }, []);

  const handleBadgeClick = useCallback((e) => {
    addTemplateTag(e.currentTarget.dataset.code);
  }, [addTemplateTag]);

  const handleEscape = useCallback(({ key }) => {
    if (key === 'Escape' && showTags) {
      setShowTags(false);
    }
  }, [showTags, setShowTags]);

  useEffect(() => {
    document.addEventListener('keydown', handleEscape);

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [handleEscape]);

  return (
    <div className="mb-2">
      <Button variant="primary" size="sm" className="btn-plain d-flex align-items-center w-100 py-2 px-0 text-black text-decoration-none" onClick={toggleTags}>
        <span>
          <SanyasIcon name="codeSlash" className="me-1" />
          Shortcodes
        </span>
        <SanyasIcon name="caretDownFill" className={`shortcodes-icon ${showTags ? 'open' : ''}`} />
      </Button>

      <div className={`shortcodes ${showTags ? 'open' : ''} text-small`} aria-expanded={showTags}>
        <div className="row">
          {Object.entries(TAG_GROUPS).map(([key, group]) => (
            <div key={key} className="col-4">
              {Object.keys(group).map((name) => (
                <div key={name}>
                  <p className="mb-0">
                    <SanyasIcon name={group[name]} className="me-1" />
                    <span className="font-weight-semibold">{name}</span>
                  </p>

                  {showTags && (
                    <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                      {groupedTags[name].map((tag) => (
                        <li key={tag.code}>
                          <EmailTemplateBadge tag={tag} onClick={handleBadgeClick} />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          ))}

          <p className="text-small text-muted">Note: Email template tags starting with &lsquo;IWL&rsquo; are for the Indigenous Welcome Letter. The Indigenous facilitator who authors this letter can be different from the participant&rsquo;s pod lead.</p>
        </div>
      </div>
    </div>
  );
}

Shortcodes.propTypes = {
  addTemplateTag: PropTypes.func.isRequired,
};

export default Shortcodes;
