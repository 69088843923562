import React from 'react';
import PropTypes from 'prop-types';
import { JournalDataPropType } from 'lib/propTypes';

const NOTIFICATION_COLOURS = {
  new: 'bg-info',
  aging: 'bg-warning',
  old: 'bg-danger',
};

function JournalDot({ data, primary, className }) {
  const count = primary ? data?.primaryJournalNotificationCount : data?.secondaryJournalNotificationCount;
  const ageLabel = primary ? data?.primaryAgeLabel : data?.secondaryAgeLabel;
  const bgOpacity = primary ? undefined : 0.5;

  if (!data || count < 1) return null;

  return (
    <span
      className={`${className} d-inline-block ${NOTIFICATION_COLOURS[ageLabel]}`}
      style={{
        borderRadius: 7,
        width: 7,
        height: 7,
        '--bs-bg-opacity': bgOpacity,
      }}
    />
  );
}

JournalDot.defaultProps = {
  data: undefined,
  primary: false,
  className: '',
};

JournalDot.propTypes = {
  data: JournalDataPropType,
  primary: PropTypes.bool,
  className: PropTypes.string,
};

export default JournalDot;
