import React from 'react';

function Hummingbird() {
  return (
    <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.9734 5.02632H19.4954C16.9987 5.02632 15.166 4.85088 14.741 4.60526C14.5551 4.5 14.4223 4.35965 14.2895 4.25439C14.0239 4.00877 13.7317 3.76316 13.3068 3.69298C13.3068 3.69298 13.174 3.65789 12.9615 3.65789C12.4834 3.65789 11.3944 3.79825 10.6773 5.16667C9.61487 3.23684 7.46348 1.09649 7.3838 0.991228L6.87915 0.5L6.95883 1.30702C7.06507 2.5 7.72908 4.67544 8.12749 5.86842C6.02922 4.85088 3.29349 4.67544 1.77955 4.67544C1.08898 4.67544 0.664011 4.71053 0.664011 4.71053L0 4.74561L0.504648 5.30702C2.62948 7.72807 5.89641 10.2895 6.85259 11.0263C2.97477 16.9912 0.345285 19.9035 0.318725 19.9386L0.63745 20.5C0.664011 20.4649 4.48871 16.6754 5.47145 16.114C5.71049 15.9737 6.18858 15.8333 6.74635 15.6579C8.63214 15.0965 11.4741 14.2895 12.4834 11.8684C12.8818 10.9211 13.174 10.0088 13.413 9.23684C13.9708 7.48246 14.3161 6.32456 15.405 6.18421L20 5.72807L19.9734 5.02632ZM13.2271 6.32456C12.9084 6.32456 12.6428 5.97368 12.6428 5.55263C12.6428 5.13158 12.9084 4.7807 13.2271 4.7807C13.5458 4.7807 13.8114 5.13158 13.8114 5.55263C13.8114 5.97368 13.5458 6.32456 13.2271 6.32456Z" fill="currentColor" />
    </svg>
  );
}

export default Hummingbird;
