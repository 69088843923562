import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { GroupMembershipPropType } from 'lib/propTypes';
import { UPDATE_GROUP_MEMBERSHIP } from 'store/groupMemberships/actions';
import { Button, Spinner } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import TextareaField from 'components/shared/FormFields/Fields/TextareaField';

function NotesField({ gm, className }) {
  const dispatch = useDispatch();

  const handleUpdate = useCallback((values, actions) => {
    dispatch(UPDATE_GROUP_MEMBERSHIP.request(values, { formikActions: actions }));
  }, [dispatch]);

  if (!gm) return null;

  return (
    <Formik
      initialValues={{ ...gm, physicianCreditType: gm.physicianCreditType ?? '', facilitatorNotes: gm.facilitatorNotes ?? '' }}
      onSubmit={handleUpdate}
      enableReinitialize
    >
      {({ dirty, isSubmitting, resetForm }) => (
        <Form className={className}>
          <label htmlFor="facilitatorNotes" className="form-label">
            Notes
            {isSubmitting && (<Spinner size="sm" animation="border" className="ms-1" role="status" />)}
          </label>
          <div className="focus-group position-relative">
            <Field
              name="facilitatorNotes"
              component={TextareaField}
              className="form-control"
              disabled={isSubmitting}
            />

            <div className="focus-item position-absolute z-1 top-100 end-0 mt-1">
              <Button variant="outline-primary" size="sm" className="sm shadow-sm" type="submit" disabled={!dirty || isSubmitting}>
                <SanyasIcon name="checkmark" />
              </Button>
              <Button variant="light" size="sm" className="ms-1 shadow-sm" disabled={!dirty || isSubmitting} onClick={() => resetForm()}>
                <SanyasIcon name="xmark" />
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

NotesField.defaultProps = {
  gm: null,
  className: '',
};

NotesField.propTypes = {
  gm: GroupMembershipPropType,
  className: PropTypes.string,
};

export default NotesField;
