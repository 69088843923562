import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Button, Spinner } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { COMMENT_FLAGS } from 'lib/constants';
import PropTypes from 'prop-types';
import { DELETE_COMMENT, UPDATE_COMMENT_FLAG } from 'store/facilitatorDashboard/actions';
import Confirmation from 'components/shared/Modal/Confirmation';
import DefaultModal from 'components/shared/Modal/DefaultModal';
import SelectField from '../FormFields/SelectField';
import SanyasIcon from '../SanyasIcon';
import './styles.scss';

function CommentCardDropdown({ disabled, setIsEditable, commentId }) {
  const dispatch = useDispatch();
  const [showFlagsModal, setShowFlagsModal] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const comment = useSelector((state) => state.facilitatorDashboard.commentsAndRepliesById[commentId]);

  const handleConfirmDeleteClose = useCallback(() => {
    setConfirmDeleteOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(DELETE_COMMENT.request({ id: commentId }));
  }, [commentId, dispatch]);

  const saveFlag = useCallback(({ flag }, actions) => {
    dispatch(UPDATE_COMMENT_FLAG.request({ id: commentId, flag: flag === '' ? null : flag }));
    setTimeout(() => {
      actions.setSubmitting(false);
      setShowFlagsModal(false);
    }, 400);
  }, [commentId, dispatch]);

  const FLAG_COLOURS = {
    [COMMENT_FLAGS.IN_PROGRESS]: 'text-accent-dark-blue',
    [COMMENT_FLAGS.CONSULT_REQUIRED]: 'text-danger',
    [COMMENT_FLAGS.ENGAGEMENT_SUPPORT]: 'text-warning',
    [COMMENT_FLAGS.OTHER]: 'text-accent-purple',
  };

  const flagOptions = Object.values(COMMENT_FLAGS).map((flag) => ({ value: flag, label: flag }));

  const formatOptionLabel = ({ value }) => (
    <div className="d-flex align-items-baseline">
      <SanyasIcon name="squareFill" size="sm" className={`me-2 ${FLAG_COLOURS[value]}`} />
      {value}
    </div>
  );

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle disabled={disabled} variant="link" size="sm" bsPrefix="no-arrow" className="mt-1">
          <SanyasIcon name="kebabHorizontal" size="xl" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={() => setShowFlagsModal(!showFlagsModal)}>
            Flag
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setIsEditable(true)}>
            Edit
          </Dropdown.Item>
          <Dropdown.Item as="button" onClick={() => setConfirmDeleteOpen(true)}>
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Confirmation
        title="Delete Post?"
        open={confirmDeleteOpen}
        close={handleConfirmDeleteClose}
        text="Are you sure you want to delete this post? This action cannot be undone."
        confirmText="Delete"
        confirmAction={handleDelete}
        cancelText={null}
      />
      <Formik
        initialValues={{ flag: comment.flag ?? '' }}
        onSubmit={saveFlag}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting }) => (
          <DefaultModal
            isOpen={showFlagsModal}
            header="Flag post?"
            onClose={() => setShowFlagsModal(false)}
            footerComponent={(
              <Button className="btn-primary" onClick={handleSubmit}>
                Flag
                {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
              </Button>
      )}
          >
            <Form>
              <p>Please choose which flag you would like to add. This will be seen by facilitators only.</p>

              <SelectField name="flag" placeholder="Select flag" options={flagOptions} formatOptionLabel={formatOptionLabel} />
            </Form>
          </DefaultModal>
        )}
      </Formik>
    </>
  );
}

CommentCardDropdown.defaultProps = {
  disabled: false,
};

CommentCardDropdown.propTypes = {
  setIsEditable: PropTypes.func.isRequired,
  commentId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default CommentCardDropdown;
