import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { COMMENT_FLAGS } from 'lib/constants';
import SanyasIcon from '../SanyasIcon';
import SimpleToolTip from '../SimpleToolTip/SimpleToolTip';

const FLAG_COLOURS = {
  [COMMENT_FLAGS.IN_PROGRESS]: 'text-accent-dark-blue',
  [COMMENT_FLAGS.CONSULT_REQUIRED]: 'text-danger',
  [COMMENT_FLAGS.ENGAGEMENT_SUPPORT]: 'text-warning',
  [COMMENT_FLAGS.OTHER]: 'text-accent-purple',
};

function CommentFlag(props) {
  const { flag, as, ...restProps } = props;

  const Component = as;

  return (
    <SimpleToolTip text={flag} placement="top">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...restProps}>
        <SanyasIcon className={FLAG_COLOURS[flag]} name="flag" />
      </Component>
    </SimpleToolTip>
  );
}

CommentFlag.defaultProps = {
  as: Fragment,
};

CommentFlag.propTypes = {
  flag: PropTypes.oneOf(Object.values(COMMENT_FLAGS)).isRequired,
  as: PropTypes.elementType,
};

export default CommentFlag;
