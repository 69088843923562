import React from 'react';
import _sortBy from 'lodash/sortBy';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { DT_CONVERSATION } from 'lib/dateFormats';
import { ConversationPropType } from 'lib/propTypes';
import MultilineText from 'components/shared/MultilineText';

function OneOnOneConversation(props) {
  return (
    <div>
      {
        _sortBy(props.conversation.messages, ['createdAt']).reverse().map((m) => (
          <div key={m.id}>
            <div>
              <p style={{ fontWeight: 'bold' }}>
                On
                {' '}
                {format(parseISO(m.createdAt), DT_CONVERSATION)}
                {', '}
                {m.fromUser.firstName}
                {' '}
                {m.fromUser.lastName}
                {' '}
                wrote:
              </p>
              <MultilineText text={m.body} />
            </div>
            <hr />
          </div>
        ))
      }
    </div>
  );
}

OneOnOneConversation.propTypes = {
  conversation: ConversationPropType.isRequired,
};

export default OneOnOneConversation;
