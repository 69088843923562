import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { ComponentPropType } from 'lib/propTypes';
import { RESET_LOGIN } from 'store/user/actions';
import { Button, Spinner } from 'react-bootstrap';
import { REDUX_STATUS } from 'lib/constants';
import Confirmation from './Modal/Confirmation';

// Roughly mimics a Formik/form field, but for displaying data only
function LoginField({ label, value, loginId, className, formLabel }) {
  const { id: workspace } = useWorkspace();
  const { status } = useSelector((state) => state.user.resetLogin);
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleLoginReset = useCallback(() => {
    dispatch(RESET_LOGIN.request({ workspace, id: loginId }));
  }, [loginId, workspace, dispatch]);

  return (
    <>
      <div className={`mb-2 ${className ?? ''}`}>
        <div className="d-flex align-items-center pb-1 ">
          <span className={`${formLabel ? 'fw-medium text-secondary' : 'd-block text-accent-grey'}`} role="presentation">{label}</span>

          <Button variant="light" size="sm" disabled={!loginId || status === REDUX_STATUS.PENDING} className="ms-2 py-0" onClick={() => setConfirmOpen(true)}>
            Reset
          </Button>
        </div>

        <span className="fw-semibold text-primary">{value}</span>

        {status === REDUX_STATUS.PENDING && (
          <Spinner variant="primary" className="ms-2" animation="border" size="sm" role="status" />
        )}
      </div>

      <Confirmation
        open={confirmOpen}
        close={() => setConfirmOpen(false)}
        text="Do you want to reset this participant’s Public Display name? It will also notify the participant."
        confirmText="Yes, reset"
        confirmAction={handleLoginReset}
        cancelText="No"
      />
    </>
  );
}

LoginField.defaultProps = {
  value: null,
  loginId: null,
  className: null,
  formLabel: false,
};

LoginField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, ComponentPropType]),
  loginId: PropTypes.number,
  className: PropTypes.string,
  formLabel: PropTypes.bool,
};

export default LoginField;
