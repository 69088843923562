import { takeLatest, put, select } from 'redux-saga/effects';
import subMinutes from 'date-fns/subMinutes';
import { fetchGet, handleSimpleFetchError } from 'lib/apiHelpers';
import { normalizeArray } from 'lib/reducerHelpers';
import { CHANGE_CURRENT_FACILITATOR, GET_DASHBOARD_FOR_FACILITATOR, GET_SECONDARY_DASHBOARD_DATA, LOAD_INITIAL_STATE, LOAD_FACILITATOR_DISCUSSION_BOARDS, GET_NOTIFICATION_COUNTS } from 'store/facilitatorDashboard/actions';

export default function* sagas() {
  yield takeLatest(CHANGE_CURRENT_FACILITATOR.SYNC, function* getDashboardForFacilitator() {
    const state = yield select();
    const userId = state.facilitatorDashboard.currentFacilitator.id;
    try {
      const { data } = yield fetchGet(
        '/api/facilitator/dashboard/notifications',
        { userId },
      );

      if (Object.keys(data.notifications).length === 0) {
        yield put(GET_DASHBOARD_FOR_FACILITATOR.success({}));
        return;
      }

      yield put(GET_DASHBOARD_FOR_FACILITATOR.success(data.notifications));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_DASHBOARD_FOR_FACILITATOR);
    }
  });

  yield takeLatest(LOAD_FACILITATOR_DISCUSSION_BOARDS.REQUEST, function* loadFacilitatorDiscussionBoards({ payload }) {
    const { currentUser } = yield select();
    const userId = payload?.id || currentUser.id;

    try {
      const { data } = yield fetchGet(
        '/api/facilitator/dashboard/notifications',
        { userId },
      );

      yield put(LOAD_FACILITATOR_DISCUSSION_BOARDS.success({ userId, data }));
    } catch (err) {
      yield handleSimpleFetchError(err, LOAD_FACILITATOR_DISCUSSION_BOARDS);
    }
  });

  yield takeLatest(GET_DASHBOARD_FOR_FACILITATOR.SUCCESS, function* getSecondaryDashboardData() {
    yield put(GET_SECONDARY_DASHBOARD_DATA.request());

    const { facilitatorDashboard: { subGroups } } = yield select();
    const subGroupIds = Object.keys(subGroups).join(',');
    if (subGroupIds.length === 0) {
      yield put(GET_SECONDARY_DASHBOARD_DATA.success());
      return;
    }
    try {
      const insufficientParticipations = yield fetchGet(
        '/api/facilitator/dashboard/insufficient_participations',
        { subGroupIds },
      );

      const flaggedJournals = yield fetchGet(
        '/api/facilitator/dashboard/flagged_journals',
        { subGroupIds },
      );

      const extendedParticipants = yield fetchGet(
        '/api/facilitator/dashboard/extended_participants',
        { subGroupIds },
      );

      yield put(GET_SECONDARY_DASHBOARD_DATA.success({ insufficientParticipations, flaggedJournals, extendedParticipants }));
    } catch (err) {
      yield handleSimpleFetchError(err, GET_SECONDARY_DASHBOARD_DATA);
    }
  });

  yield takeLatest(LOAD_INITIAL_STATE.REQUEST, function* loadInitialState() {
    const { currentUser } = yield select();
    // Get all facilitators for the dropDown
    const allFacilitators = yield fetchGet('/api/facilitator/facilitators');
    const sortedFacilitators = allFacilitators
      .data
      .filter((facilitator) => {
        if (currentUser.isSuperFacilitator || currentUser.isRegistrar) {
          // show all facilitators and super facilitators
          return facilitator.isFacilitator || facilitator.isSuperFacilitator;
        }
        // regular facilitators can only see other regular facilitators
        return facilitator.isFacilitator && !facilitator.isSuperFacilitator;
      })
      .sort((a, b) => {
        const nameA = a.firstName.toLowerCase();
        const nameB = b.firstName.toLowerCase();
        if (nameA < nameB) { return -1; } // sort string ascending
        if (nameA > nameB) { return 1; }
        return 0; // default return value (no sorting)
      });

    yield put(LOAD_INITIAL_STATE.success({ facilitators: normalizeArray(sortedFacilitators) }));
    if (currentUser.isFacilitator || currentUser.isSuperFacilitator) {
      yield put(CHANGE_CURRENT_FACILITATOR.action({ id: currentUser.id }));
    } else {
      // Load the first facilitator if the current user is a registrar
      // or San'yas Sysadmin
      yield put(CHANGE_CURRENT_FACILITATOR.action({ id: sortedFacilitators[0].id }));
    }
  });

  yield takeLatest(GET_NOTIFICATION_COUNTS.REQUEST, function* getNotificationCounts({ payload }) {
    const { facilitatorDashboard } = yield select();
    if ((facilitatorDashboard.notificationTotals?.cache ?? 0) < subMinutes(new Date(), 5)) { // cache for 5 minutes
      try {
        const { data } = yield fetchGet('/api/facilitator/dashboard/notification_counts', payload);
        yield put(GET_NOTIFICATION_COUNTS.success(data, { cached: false }));
      } catch (err) {
        yield handleSimpleFetchError(err, GET_NOTIFICATION_COUNTS);
      }
    } else {
      yield put(GET_NOTIFICATION_COUNTS.success({}, { cached: true }));
    }
  });
}
