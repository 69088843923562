import React, { useRef, useState } from 'react';
import { Button, CloseButton, Overlay, Popover } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import { FacilitatorNotePropType } from 'lib/propTypes';

function QuicknotesPopover({ note }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Button variant={null} ref={target} className="p-0 border-0" onClick={() => setShow(!show)} disabled={!note?.quicknotes}>
        <SanyasIcon name="notes" size="xl" className={`${note?.quicknotes ? 'text-accent-land' : 'text-accent-grey'}`} />
      </Button>

      {note?.quicknotes && (
        <Overlay
          target={target.current}
          placement="left"
          show={show}
          rootClose
          onHide={() => setShow(false)}
        >
          <Popover id={`quicknotes-${note.id}`} style={{ minWidth: '12rem' }}>
            <Popover.Body className="p-2">
              <div className="d-flex align-items-center">
                <div className="mb-0 fs-4 fw-semibold">
                  Quick notes
                </div>

                <CloseButton className="ms-auto" onClick={() => setShow(false)} />
              </div>
              <p className="my-1" style={{ whiteSpace: 'pre-line' }}>
                {note.quicknotes}
              </p>
            </Popover.Body>
          </Popover>
        </Overlay>
      )}
    </>
  );
}

QuicknotesPopover.defaultProps = {
  note: {},
};

QuicknotesPopover.propTypes = {
  note: FacilitatorNotePropType,
};

export default QuicknotesPopover;
