import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType } from 'lib/propTypes';
import { Button, Offcanvas } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';

/*
 * Slide-in Panel component
 * see https://react-bootstrap.github.io/docs/components/offcanvas#offcanvas
 * for optional props
 */

function SlideInPanel({ title, show, onHide, children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Offcanvas show={show} onHide={onHide} {...props}>
      <div className="d-flex align-items-center border-bottom p-1 pt-2">
        <Button variant={null} size="sm" onClick={onHide} aria-label="Close">
          <SanyasIcon name="xmark" size="xl" />
        </Button>
        {title}
      </div>
      <Offcanvas.Body className="p-0">
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

SlideInPanel.defaultProps = {
  show: false,
  onHide: null,
};

SlideInPanel.propTypes = {
  title: ComponentPropType.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: ComponentPropType.isRequired,
};

export default SlideInPanel;
