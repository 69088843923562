import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { DT_CONVERSATION } from 'lib/dateFormats';
import { ConversationPropType } from 'lib/propTypes';
import { FACILITATOR_GROUP_SHOW_PATH } from 'lib/routerPaths';
import MultilineText from 'components/shared/MultilineText';
import { buildRoutePath } from 'lib/routerHelpers';

function GroupConversation({ conversation }) {
  const firstMessage = conversation.messages[0];
  const recipients = conversation
    .messages
    .map((m) => `${m.toUser.firstName} ${m.toUser.lastName}`)
    .sort((a, b) => { if (a > b) { return 1; } return -1; })
    .join(', ');
  const { groupName } = firstMessage.toUser;
  const { groupId } = firstMessage.toUser;

  return (
    <div className="row-space-top">
      <p className="well">
        This message was sent to your pod in
        {' '}
        <Link to={buildRoutePath(FACILITATOR_GROUP_SHOW_PATH, { id: groupId })}>{groupName}</Link>
        .
        <br />
        <br />
        <small>{recipients}</small>
      </p>
      <div>
        <p style={{ fontWeight: 'bold' }}>
          On
          {' '}
          {format(parseISO(firstMessage.createdAt), DT_CONVERSATION)}
          {', '}
          {firstMessage.fromUser.firstName}
          {' '}
          {firstMessage.fromUser.lastName}
          {' '}
          wrote:
        </p>
        <MultilineText text={firstMessage.body} />
      </div>
    </div>
  );
}

GroupConversation.propTypes = {
  conversation: ConversationPropType.isRequired,
};

export default GroupConversation;
