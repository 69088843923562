import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { FacilitatorNotePropType } from 'lib/propTypes';
import { CREATE_FACILITATOR_NOTE, UPDATE_FACILITATOR_NOTE } from 'store/facilitatorDashboard/actions';
import SlideInPanel from 'components/shared/SlideInPanel';
import TextareaField from 'components/shared/FormFields/TextareaField';
import RichTextField from 'components/shared/FormFields/RichTextField';
import AutoSave from 'components/shared/AutoSave';

function NotesPanel({ note, show, onHide }) {
  const { subGroupId, discussionTaskId } = useParams();
  const dispatch = useDispatch();

  const initialValues = { subGroupId: Number(subGroupId), taskId: Number(discussionTaskId), ...note };
  const minHeight = window.innerHeight * 0.6;

  const handleSubmit = useCallback((values, actions) => {
    if (values.id) {
      dispatch(UPDATE_FACILITATOR_NOTE.request(values, { formikActions: actions }));
    } else {
      dispatch(CREATE_FACILITATOR_NOTE.request(values, { formikActions: actions }));
    }
  }, [dispatch]);

  return (
    <Formik
      key={note?.id || `${subGroupId}-${discussionTaskId}`}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {() => (
        <SlideInPanel
          title={(
            <>
              <h4 className="mb-1">Notes</h4>
              <span className="ms-auto pe-3">
                <AutoSave debounceMs={1200} spinner />
              </span>
            </>
          )}
          show={show}
          onHide={onHide}
          scroll
          backdrop={false}
        >
          <Form>
            <TextareaField
              name="quicknotes"
              placeholder="Notes that help me remember why I prioritized it"
              helpText="These notes will be visible from the dashboard (limit 100 char)"
              maxLength={100}
              inline
              className="p-3"
            />

            <div className="border-top">
              <RichTextField variant="minimal" name="notes" hideLabel className="border-0" options={{ min_height: minHeight, statusbar: false }} />
            </div>
          </Form>
        </SlideInPanel>
      )}
    </Formik>
  );
}

NotesPanel.defaultProps = {
  note: {},
  show: false,
  onHide: null,
};

NotesPanel.propTypes = {
  note: FacilitatorNotePropType,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

export default NotesPanel;
