import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PRIORITY_CLASSES = {
  high: 'priority3',
  medium: 'priority2',
  low: 'priority1',
};

function priorityClass(priority) {
  return `badge ${PRIORITY_CLASSES[priority]}`;
}

function NotificationBubble({ priority, href, to, count }) {
  return (
    <span className={priorityClass(priority)}>
      {!!href && (
        <a style={{ color: 'white' }} href={href} target="_blank" rel="noreferrer">{count}</a>
      )}
      {!!to && (
        <Link style={{ color: 'white' }} to={to}>{count}</Link>
      )}
      {(!href && !to) && count}
    </span>
  );
}

NotificationBubble.defaultProps = {
  priority: 'priority1',
  href: null,
  to: null,
  count: 0,
};

NotificationBubble.propTypes = {
  priority: PropTypes.oneOf(Object.keys(PRIORITY_CLASSES)),
  href: PropTypes.string,
  to: PropTypes.string,
  count: PropTypes.number,
};

export default NotificationBubble;
