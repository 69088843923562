import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { LEGACY_APP_DOMAIN } from 'config';
import { useWorkspace } from 'lib/hooks/useWorkspace';
import { GroupPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_COMMENTS_PATH } from 'lib/routerPaths';
import { GET_LIVE_FEED } from 'store/actions';
import Spinner from 'components/shared/Spinner';

const TASK_TYPES = {
  SubGroupDiscussionTask: 'Pod Discussion',
  JournalTask: 'Journal',
};

function GroupLiveFeedTabPage({ group }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { id: workspace } = useWorkspace();
  const loading = useSelector((state) => state.groupShow.notificationsLoading);
  const notifications = useSelector((state) => state.groupShow.notifications);
  const [notificationType, setType] = useState('group');

  useEffect(() => {
    dispatch(GET_LIVE_FEED.request({ id, feed: notificationType, workspace }));
  }, [id, notificationType, workspace, dispatch]);

  const arrayNotifications = useMemo(() => Object.values(notifications || {}), [notifications]);

  function handleNotificationTypeChange(event) {
    const newType = event.target.value;
    setType(newType);
    dispatch(GET_LIVE_FEED.request({ id, feed: newType, workspace }));
  }

  return (
    <>
      <div className="col-md-3 mb-3">
        <label className="form-label" htmlFor="feed-type">
          Notifications
        </label>

        <select className="form-select" name="feed-type" id="feed-type" value={notificationType} onChange={handleNotificationTypeChange}>
          <option value="group">Group</option>
          <option value="personalized">Personalized</option>
        </select>
      </div>

      { loading && <Spinner message={`Loading ${notificationType} notifications...`} /> }

      { !loading && (
        <>

          <p className="text-secondary">
            {`Found ${arrayNotifications.length} ${notificationType} notifications.`}
          </p>

          {(arrayNotifications.length > 0) && (
            arrayNotifications.map((notification) => (
              <div className="col-md-8" key={notification.id}>
                <div className="card mb-3 border">
                  <div className="card-header d-flex align-items-center justify-content-between">
                    <span>{notification.taskName}</span>
                    <span className="badge rounded-pill bg-light text-secondary">{TASK_TYPES[notification.taskType]}</span>
                  </div>

                  <div className="card-body">
                    <div className="d-flex">
                      <div className="me-3">
                        {(notification.taskType === 'SubGroupDiscussionTask') && (
                          <i className="fas fa-fw fa-comments fa-3x" />
                        )}
                        {(notification.taskType === 'JournalTask') && (
                          <i className="fas fa-fw fa-book fa-3x" />
                        )}
                      </div>

                      <div className="flex-grow-1">
                        <h5 className="card-title">
                          {(notification.taskType === 'SubGroupDiscussionTask') && (
                            <Link
                              to={buildRoutePath(FACILITATOR_COMMENTS_PATH, { id: group.id, subGroupId: notification.subGroupId, discussionTaskId: notification.taskId })}
                              className="stretched-link"
                            >
                              {notification.body}
                            </Link>
                          )}
                          {(notification.taskType === 'JournalTask') && (
                            <a
                              href={`${LEGACY_APP_DOMAIN}/group_memberships/${notification.triggerGroupMembershipId}/journal_tasks/${notification.taskId}`}
                              target="_blank"
                              rel="noreferrer"
                              className="stretched-link"
                            >
                              {notification.body}
                            </a>
                          )}
                        </h5>

                        <h6 className="card-subtitle mb-2 text-muted">
                          {formatDistanceToNow(parseISO(notification.createdAt), { addSuffix: true })}
                        </h6>

                        <p className="card-text">{notification.excerpt}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </>
      )}
    </>
  );
}

GroupLiveFeedTabPage.defaultProps = {
  group: {},
};

GroupLiveFeedTabPage.propTypes = {
  group: GroupPropType,
};

export default GroupLiveFeedTabPage;
