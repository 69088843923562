import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPropType, StylePropType } from 'lib/propTypes';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function SimpleToolTip({ id, placement, delay, text, variant, width, children, innerClassName, innerStyle }) {
  const style = {};

  if (width) {
    style['--bs-tooltip-max-width'] = typeof width === 'number' ? `${width}px` : width;
  }

  if (variant !== 'normal') {
    style['--bs-tooltip-bg'] = `var(--bs-${variant})`;

    if (variant === 'warning' || variant === 'light') {
      style['--bs-tooltip-color'] = 'var(--bs-dark)';
    }
  }

  return (
    <OverlayTrigger
      placement={placement}
      delay={delay}
      overlay={(
        <Tooltip id={id || `simple-tool-tip-${placement}`} style={style}>
          {text}
        </Tooltip>
      )}
    >
      <span
        className={innerClassName}
        style={innerStyle}
      >
        {children}
      </span>
    </OverlayTrigger>
  );
}

SimpleToolTip.defaultProps = {
  id: null,
  placement: 'auto',
  delay: null,
  variant: 'normal',
  width: null,
  innerClassName: null,
  innerStyle: null,
};

SimpleToolTip.propTypes = {
  id: PropTypes.string,
  placement: PropTypes.oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
  ]),
  delay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      show: PropTypes.number,
      hide: PropTypes.number,
    }),
  ]),
  text: PropTypes.oneOfType([
    PropTypes.string,
    ComponentPropType,
  ]).isRequired,
  variant: PropTypes.oneOf([
    'normal',
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  children: ComponentPropType.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  innerClassName: PropTypes.string,
  innerStyle: StylePropType,
};

export default SimpleToolTip;
