import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CommentPropType } from 'lib/propTypes';
import { CREATE_REPLY_COMMENT } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import Textarea from 'components/shared/FormFields/Elements/Textarea';
import WordCount from './WordCount';
import './styles.scss';

function Reply({ comment, setShowReplyBox }) {
  const dispatch = useDispatch();
  const [reply, setComment] = useState('');
  const { fullName } = useSelector((state) => state.currentUser);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setShowReplyBox(false);
    dispatch(CREATE_REPLY_COMMENT.request({ newComment: reply, replyToCommentId: comment.id, subGroupId: comment.subGroupId, discussionTaskId: comment.taskId }));
  }, [comment.id, comment.subGroupId, comment.taskId, dispatch, reply, setShowReplyBox]);

  return (
    <div className="reply-container">
      <div className="border-bottom p-2">
        {`Reply to ${comment.author} as ${fullName}`}
      </div>

      <form onSubmit={handleSubmit}>
        <Textarea
          name="postContent"
          value={reply}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Start typing here"
          className="form-control-md p-2 reply-textarea d-block"
        />
        <div className="d-flex align-items-center border-top p-2">
          <WordCount text={reply} />
          <Button type="submit" variant="primary" className="ms-auto">Reply</Button>
        </div>
      </form>
    </div>
  );
}

Reply.defaultProps = {
};

Reply.propTypes = {
  comment: CommentPropType.isRequired,
  setShowReplyBox: PropTypes.func.isRequired,
};

export default Reply;
