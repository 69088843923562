import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { copyGlossaryEntry } from 'lib/utils';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import CopyButton from 'components/shared/Button/CopyButton';

function GlossaryEntryFormHeader({ setShowDeleteModal }) {
  const { values, dirty, isSubmitting, handleSubmit } = useFormikContext();

  return (
    <div className="d-flex align-items-center py-3 px-4 border-bottom">
      <h4 className="m-0 text-truncate">{values?.term || 'New entry'}</h4>

      <span className="ms-auto flex-shrink-0">
        {!!values.id && (<CopyButton variant="secondary" size="sm" className="btn-plain me-1" result={copyGlossaryEntry(values)} disabled={dirty} />)}

        <Button type="submit" variant="primary" size="sm" className="ms-auto" disabled={isSubmitting} onClick={handleSubmit}>
          {isSubmitting && (<Spinner animation="border" size="sm" className="me-1" role="status" />)}
          Save
        </Button>

        <Dropdown className="d-inline-block">
          <Dropdown.Toggle variant={null} bsPrefix="no-arrow" className="py-0">
            <SanyasIcon name="kebabVertical" size="lg" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 4],
                  },
                },
              ],
            }}
          >
            <Dropdown.Item as="button" type="button" disabled={!values?.id || isSubmitting} onClick={() => setShowDeleteModal(true)}>
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </span>
    </div>
  );
}

GlossaryEntryFormHeader.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

export default GlossaryEntryFormHeader;
