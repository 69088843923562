import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CommentPropType } from 'lib/propTypes';
import { UPDATE_COMMENT } from 'store/facilitatorDashboard/actions';
import { Button } from 'react-bootstrap';
import Textarea from 'components/shared/FormFields/Elements/Textarea';
import WordCount from './WordCount';
import './styles.scss';

function EditCommentCard({ comment, setIsEditable }) {
  const dispatch = useDispatch();
  const [newComment, setComment] = useState(comment.comment);
  const currentUser = useSelector((state) => state.currentUser);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    setComment('');
    setIsEditable(false);
    const updatedComment = comment;
    updatedComment.comment = newComment;
    dispatch(UPDATE_COMMENT.request({ updatedComment }));
  }, [comment, dispatch, newComment, setIsEditable]);

  return (
    <div className="reply-container col-md-12">
      <div>
        <div className="border-bottom p-2">
          {`Edit ${comment.author}’s post as ${currentUser?.fullName}`}
        </div>

        <form onSubmit={handleSubmit}>
          <Textarea
            name="postContent"
            onChange={(e) => setComment(e.target.value)}
            value={newComment}
            placeholder="Start typing here"
            className="form-control-md p-2 reply-textarea"
          />

          <div className="d-flex align-items-center border-top p-2">
            <WordCount text={newComment} />

            <Button type="submit" variant="primary" className="ms-auto">Comment</Button>
            <Button
              className="btn-plain ms-1"
              aria-label="Close"
              variant="primary"
              onClick={() => setIsEditable(false)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

EditCommentCard.defaultProps = {
};

EditCommentCard.propTypes = {
  comment: CommentPropType.isRequired,
  setIsEditable: PropTypes.func.isRequired,
};

export default EditCommentCard;
