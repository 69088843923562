import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { OrganizationPropType } from 'lib/propTypes';
import { selectEmailTemplateWithTypeId } from 'store/emailTemplates/selectors';
import Button from 'react-bootstrap/Button';
import SanyasIcon from '../SanyasIcon';
import SimpleToolTip from '../SimpleToolTip/SimpleToolTip';

function EmailTemplatesListItem({ template, active, setSelectedTemplate, organization }) {
  const emailTemplate = useSelector((state) => selectEmailTemplateWithTypeId(state, template.id));

  if (!template) return null;

  return (
    <li className="list-group-item py-3 ps-4 hover-group hover-highlight">
      {active && (
        <Button variant="link" className="btn-plain w-100 px-0 text-start text-black stretched-link" onClick={() => setSelectedTemplate(template.id)}>
          <h4>
            {template.displayName}
            {!emailTemplate?.body && (
              <SimpleToolTip variant="warning" text="This template is empty and will not send.">
                <SanyasIcon name="exclamationTriangle" className="ms-2 text-warning" />
              </SimpleToolTip>
            )}
            {(organization && !organization.sendManagerNotifications && template?.managerNotification) && (
              <SimpleToolTip variant="warning" text="This organization does not send manager notifications.">
                <SanyasIcon name="exclamationTriangle" className="ms-2 text-warning" />
              </SimpleToolTip>
            )}
          </h4>
        </Button>
      )}

      {!active && (
        <div className="d-flex align-items-center m-0">
          <h4>{template.displayName}</h4>

          <Button variant="primary" size="sm" className="ms-auto" onClick={() => setSelectedTemplate(template.id)}>
            Add
          </Button>
        </div>
      )}
      <p className="text-secondary">{template.description}</p>
    </li>
  );
}

EmailTemplatesListItem.defaultProps = {
  active: false,
  organization: null,
};

EmailTemplatesListItem.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    displayName: PropTypes.string,
    managerNotification: PropTypes.bool,
  }).isRequired,
  active: PropTypes.bool,
  setSelectedTemplate: PropTypes.func.isRequired,
  organization: OrganizationPropType,
};

export default EmailTemplatesListItem;
