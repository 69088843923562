import React, { useCallback, useState } from 'react';
import SanyasIcon from 'components/shared/SanyasIcon';
import { Button } from 'react-bootstrap';
import { UserPropType } from 'lib/propTypes';
import Confirmation from 'components/shared/Modal/Confirmation';
import { useDispatch } from 'react-redux';
import { DELETE_USER } from 'store/user/actions';

function DeleteUser({ user }) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const closeModal = useCallback(() => setShowModal(false), []);

  const handleConfirm = useCallback(() => {
    dispatch(DELETE_USER.request(user.id));
  }, [user.id, dispatch]);

  if (!user.isParticipant) return null; // Only participants can be deleted

  return (
    <>
      <SanyasIcon name="trashCan" className="me-2 text-danger" />
      <span>
        <Button variant="link" className="d-block btn-plain p-0 text-start" onClick={() => setShowModal(true)}>
          Delete account
        </Button>
        <span className="d-block text-small text-secondary">
          This will delete the account and all content created by this account. This action cannot be undone.
        </span>
      </span>

      <Confirmation
        open={showModal}
        close={closeModal}
        title="Delete account?"
        text="This will delete the account and all content created by this account. This action cannot be undone."
        confirmText="Delete account"
        confirmAction={handleConfirm}
        cancelText={null}
        delay={800}
      />
    </>
  );
}

DeleteUser.propTypes = {
  user: UserPropType.isRequired,
};

export default DeleteUser;
