import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import CourseDraftCreateDialog from 'components/curriculum/CourseDraftCreateDialog';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { COURSE_STATUS } from 'lib/constants';
import { CoursePropType, StylePropType } from 'lib/propTypes';
import { CURRICULUM_COURSE_GLOSSARY_PATH, CURRICULUM_COURSE_PHRASEBOOK_PATH, CURRICULUM_COURSE_SHOW_PATH, CURRICULUM_COURSE_FIND_AND_REPLACE_PATH, CURRICULUM_COURSE_COMPLETION_REPORT_PATH, CURRICULUM_COURSE_SELF_CARE_PATH } from 'lib/routerPaths';
import { buildRoutePath } from 'lib/routerHelpers';
import { ARCHIVE_COURSE, DUPLICATE_COURSE } from 'store/courses/actions';
import Dropdown from 'react-bootstrap/Dropdown';
import Confirmation from 'components/shared/Modal/Confirmation';
import SanyasIcon from 'components/shared/SanyasIcon';
import { selectCourseTypeById } from 'store/courseTypes/selectors';
import CopyCourseForm from '../CopyCourseForm/CopyCourseForm';
import SettingsDialog from './SettingsDialog';

const alignKeys = {
  start: 'marginLeft',
  end: 'marginRight',
};

function CourseDropDown({ course, alignToggle, className, style }) {
  const courseType = useSelector((state) => selectCourseTypeById(state, course.courseTypeId));
  const dispatch = useDispatch();
  const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);
  const [duplicateConfirmOpen, setDuplicateConfirmOpen] = useState(false);
  const [copyToConfirmOpen, setCopyToConfirmOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const alignStyle = alignToggle ? { [alignKeys[alignToggle]]: 'calc(-1 * var(--bs-btn-padding-x))' } : null;

  const archivable = [COURSE_STATUS.DRAFT, COURSE_STATUS.CLOSED].includes(course?.status);

  const handleArchive = useCallback(() => {
    if (course?.id) {
      dispatch(ARCHIVE_COURSE.request(course?.id));
    }
  }, [course?.id, dispatch]);

  const handleDuplicate = useCallback((values, actions) => {
    if (course?.id) {
      dispatch(DUPLICATE_COURSE.request(values, { formikActions: actions }));
    }
  }, [course?.id, dispatch]);

  if (!course) return null;

  return (
    <>
      <Dropdown
        className={`ms-auto ${className || ''}`}
        style={style}
      >
        <Dropdown.Toggle variant={null} bsPrefix="no-arrow" className="py-0" style={{ ...style, ...alignStyle }}>
          <SanyasIcon name="kebabVertical" size="lg" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" onClick={() => setSettingsOpen(true)}>
            Settings
          </Dropdown.Item>

          <Dropdown.Item as={Link} to={buildRoutePath(CURRICULUM_COURSE_FIND_AND_REPLACE_PATH, { courseId: course?.id })}>
            Find/Replace
          </Dropdown.Item>
          {courseType.showSelfCareTab && (
            <Dropdown.Item as={Link} to={buildRoutePath(CURRICULUM_COURSE_SELF_CARE_PATH, { courseId: course?.id })}>
              Self Care
            </Dropdown.Item>
          )}

          {(course?.status !== COURSE_STATUS.DRAFT) && (
            <>
              <Dropdown.Item as={Link} to={`${buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id })}/proactive_templates`}>
                Proactive Templates
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={`${buildRoutePath(CURRICULUM_COURSE_SHOW_PATH, { courseId: course?.id })}/email_templates`}>
                Email Templates
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={buildRoutePath(CURRICULUM_COURSE_GLOSSARY_PATH, { courseId: course?.id })}>
                Glossary
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={buildRoutePath(CURRICULUM_COURSE_PHRASEBOOK_PATH, { courseId: course?.id })}>
                Phrasebook
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={buildRoutePath(CURRICULUM_COURSE_COMPLETION_REPORT_PATH, { courseId: course?.id })}>
                Completion Report
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item
            as="button"
            onClick={() => setDuplicateConfirmOpen(true)}
            disabled={course.isDuplicating || course.isPublishing}
          >
            Duplicate
          </Dropdown.Item>

          <Dropdown.Item
            as="button"
            onClick={() => setCopyToConfirmOpen(true)}
            disabled={course.isDuplicating || course.isPublishing}
          >
            Copy to...
          </Dropdown.Item>

          { course.status !== COURSE_STATUS.ARCHIVED && (
            <Dropdown.Item as="button" onClick={() => setArchiveConfirmOpen(true)} disabled={!archivable}>
              Archive
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>

      <Confirmation
        open={archiveConfirmOpen}
        close={() => setArchiveConfirmOpen(false)}
        title="Confirm Archiving"
        text={`Please confirm you would like to archive: ${course.displayName}?`}
        confirmText="Archive"
        confirmAction={handleArchive}
        cancelText={false}
        delay={800}
        delayText="Archiving..."
      />

      <CourseDraftCreateDialog
        courseTypeId={Number(course.courseTypeId)}
        show={duplicateConfirmOpen}
        onClose={() => setDuplicateConfirmOpen(false)}
        onSubmit={handleDuplicate}
        courseId={course?.id}
        isDuplicating
      />

      <CopyCourseForm
        currentCourseId={Number(course.id)}
        showCopyModal={copyToConfirmOpen}
        header={`Copy ${course?.name}`}
        setShowCopyModal={setCopyToConfirmOpen}
      />

      <SettingsDialog
        settingsOpen={settingsOpen}
        setSettingsOpen={setSettingsOpen}
        courseId={course?.id}
      />
    </>
  );
}

CourseDropDown.defaultProps = {
  course: null,
  alignToggle: null,
  className: null,
  style: null,
};

CourseDropDown.propTypes = {
  course: CoursePropType,
  alignToggle: PropTypes.oneOf(['start', 'end']),
  className: PropTypes.string,
  style: StylePropType,
};

export default CourseDropDown;
