import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { REDUX_STATUS } from 'lib/constants';
import { GroupPropType } from 'lib/propTypes';
import { LOAD_RESERVATIONS, UPDATE_RESERVATIONS } from 'store/groupShow/actions';
import { selectReservationsByHealthAuthority } from 'store/groupShow/selectors';
import { Alert, Button, Spinner } from 'react-bootstrap';
import ErrorMessage from 'components/shared/ErrorMessage';
import SharedSpinner from 'components/shared/Spinner';

const FormSchema = (maxParticipants) => Yup.object().shape({
  reservations: Yup.array().of(
    Yup.object().shape({
      seats: Yup.number().optional('Seats are required'),
    }).test('totalSeats', `Total seats must not exceed Max Participants ${maxParticipants}`, (value, context) => {
      const totalSeats = context.parent.reduce((total, reservation) => total + parseInt(reservation.seats || 0, 10), 0);
      return totalSeats <= maxParticipants;
    }),
  ),
});

function GroupSettingsReservationsPage({ group }) {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.groupShow.reservations);
  const reservations = useSelector((state) => selectReservationsByHealthAuthority(state, group.organizationId));

  useEffect(() => {
    dispatch(LOAD_RESERVATIONS.request({ groupId: group.id }));
  }, [group.id, dispatch]);

  const handleSubmit = useCallback((values, actions) => {
    dispatch(UPDATE_RESERVATIONS.request(values, { formikActions: actions }));
    document.activeElement.blur(); // Remove focus from submit button or input, otherwise it looks weird
  }, [dispatch]);

  if (status === REDUX_STATUS.PENDING) return <SharedSpinner />;

  return (
    <>
      <ErrorMessage error={error} />

      <Formik
        onSubmit={handleSubmit}
        initialValues={{ groupId: group.id, reservations }}
        validationSchema={FormSchema(group.maxParticipants)}
        enableReinitialize
        validateOnBlur
      >
        {({ values, resetForm, errors, isSubmitting }) => (
          <Form>
            {errors.reservations && errors.reservations.length > 0 && (
              <Alert variant="danger" className="py-2">{errors.reservations[0]}</Alert>
            )}

            <ul className="list-unstyled list-striped">
              {values.reservations.map((reservation, index) => (
                <li
                  key={reservation.healthAuthorityId}
                  className="d-flex align-items-center"
                >
                  <label
                    htmlFor={`reservations-${index}-seats`}
                    className="col-4 col-md-3 text-truncate pe-2"
                  >
                    {reservation.healthAuthorityName}
                  </label>

                  <div className="col-2 col-xl-1 me-2">
                    <Field
                      className="form-control"
                      id={`reservations-${index}-seats`}
                      min="0"
                      type="number"
                      name={`reservations.${index}.seats`}
                      value={reservation.seats}
                    />
                  </div>

                  <div className="ms-3">
                    <Field name={`reservations.${index}._delete`} id={`reservations.${index}._delete`} className="form-check-input me-2" type="checkbox" />
                    <label htmlFor={`reservations.${index}._delete`}>Delete</label>
                  </div>
                </li>
              ))}
            </ul>

            <div>
              <Button variant="primary" type="submit" className="me-3" disabled={Object.keys(errors).length > 0 || isSubmitting}>
                Save
                {isSubmitting && <Spinner size="sm" className="ms-1" animation="border" role="status" />}
              </Button>

              <Button variant={null} className="btn-plain" onClick={() => resetForm()}>
                Reset values
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

GroupSettingsReservationsPage.propTypes = {
  group: GroupPropType.isRequired,
};

export default GroupSettingsReservationsPage;
