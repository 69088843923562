import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InsufficientParticipationPropType } from 'lib/propTypes';
import { buildRoutePath } from 'lib/routerHelpers';
import { FACILITATOR_PARTICIPANTS_SHOW_PATH } from 'lib/routerPaths';
import Spinner from 'components/shared/Spinner';

function InsufficientParticipationList({ ips }) {
  if (!ips) {
    return <div className="col-md-4 text-center"><Spinner /></div>;
  }
  if (ips.length === 0) {
    return <div className="col-md-4" />;
  }
  ips.sort((a, b) => {
    if (a.fullName > b.fullName) return 1;
    if (a.fullName < b.fullName) return -1;
    return 0;
  });
  return (
    <div className="col-md-4 text-start">
      <h5 style={{ marginTop: 0 }}>Insufficient Participations</h5>
      {
        ips.map((participant, index) => (
          <span key={participant.groupMembershipId}>
            <Link to={buildRoutePath(FACILITATOR_PARTICIPANTS_SHOW_PATH, { groupMembershipId: participant.groupMembershipId })}>{participant.fullName}</Link>
            { index !== ips.length - 1 && (<span className="px-1">●</span>)}
          </span>
        ))
      }
    </div>
  );
}

InsufficientParticipationList.defaultProps = {
  ips: [],
};

InsufficientParticipationList.propTypes = {
  ips: PropTypes.arrayOf(InsufficientParticipationPropType),
};

export default InsufficientParticipationList;
