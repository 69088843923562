import { REDUX_STATUS, REDUX_SUCCESS } from 'lib/constants';
import {
  LOAD_GROUP_MEMBERSHIPS,
  LOAD_GROUP_MEMBERSHIP,
  LOAD_GROUP_MEMBERSHIPS_FORM_DATA,
  LOAD_LOG_ENTRIES,
  RESET_GROUP_MEMBERSHIPS,
  UPDATE_CURRICULUM_ACCESS,
  UPDATE_INSUFFICIENT_PARTICIPATION,
  UPDATE_GROUP_MEMBERSHIP,
  LOAD_JOURNAL_AND_DISCUSSION_BOARDS,
  UPDATE_MARKED_AS_INDIGENOUS,
  RESEND_IWL,
  GET_IWL_STATUS,
} from './actions';

const initialState = {
  status: REDUX_STATUS.IDLE,
  data: undefined,
  error: undefined,
  success: undefined,
  formData: {
    status: REDUX_STATUS.IDLE,
    data: undefined,
    error: undefined,
  },
  logEntries: {
    groupMembershipId: undefined,
    status: REDUX_STATUS.IDLE,
    allIds: [],
    byId: undefined,
    error: undefined,
  },
  iwlStatus: {
    status: REDUX_STATUS.IDLE,
    error: undefined,
    data: undefined,
  },
  journalsDiscussionBoards: {
    discussionBoards: [],
    journalTasks: [],
    status: undefined,
    error: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GROUP_MEMBERSHIPS.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
        error: undefined,
      };
    case LOAD_GROUP_MEMBERSHIPS.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS };

      return {
        ...state,
        data: action.payload,
        status: REDUX_STATUS.SUCCESS,
      };
    }
    case LOAD_GROUP_MEMBERSHIPS.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_GROUP_MEMBERSHIP.REQUEST:
      return {
        ...state,
        status: REDUX_STATUS.PENDING,
        error: undefined,
      };
    case LOAD_GROUP_MEMBERSHIP.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS };

      return {
        ...state,
        data: [action.payload],
        status: REDUX_STATUS.SUCCESS,
      };
    }
    case LOAD_GROUP_MEMBERSHIP.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_LOG_ENTRIES.REQUEST:
      return {
        ...state,
        logEntries: {
          ...state.logEntries,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_LOG_ENTRIES.SUCCESS: {
      if (action.meta.cached) return { ...state, logEntries: { ...state.logEntries, status: REDUX_STATUS.SUCCESS } };

      return {
        ...state,
        logEntries: {
          ...state.logEntries,
          groupMembershipId: action.meta.groupMembershipId,
          allIds: action.payload.logEntryIds,
          byId: action.payload.logEntries,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_LOG_ENTRIES.ERROR:
      return {
        ...state,
        logEntries: {
          ...state.logEntries,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case LOAD_GROUP_MEMBERSHIPS_FORM_DATA.REQUEST:
      return {
        ...state,
        formData: {
          ...state.formData,
          status: REDUX_STATUS.PENDING,
        },
      };
    case LOAD_GROUP_MEMBERSHIPS_FORM_DATA.SUCCESS: {
      if (action.meta.cached) return { ...state, status: REDUX_STATUS.SUCCESS, error: undefined };

      return {
        ...state,
        formData: {
          ...state.formData,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
          error: undefined,
        },
      };
    }
    case LOAD_GROUP_MEMBERSHIPS_FORM_DATA.ERROR:
      return {
        ...state,
        formData: {
          ...state.formData,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case UPDATE_GROUP_MEMBERSHIP.REQUEST:
      return {
        ...state,
        success: undefined,
        error: undefined,
      };
    case UPDATE_GROUP_MEMBERSHIP.SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: REDUX_STATUS.SUCCESS,
        success: REDUX_SUCCESS.SAVED,
      };
    case UPDATE_GROUP_MEMBERSHIP.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case UPDATE_CURRICULUM_ACCESS.REQUEST:
      return {
        ...state,
        success: undefined,
        error: undefined,
      };
    case UPDATE_CURRICULUM_ACCESS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: REDUX_STATUS.SUCCESS,
      };
    case UPDATE_CURRICULUM_ACCESS.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };
    case UPDATE_INSUFFICIENT_PARTICIPATION.REQUEST:
      return {
        ...state,
        success: undefined,
        error: undefined,
      };
    case UPDATE_INSUFFICIENT_PARTICIPATION.SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: REDUX_STATUS.SUCCESS,
      };
    case UPDATE_INSUFFICIENT_PARTICIPATION.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case LOAD_JOURNAL_AND_DISCUSSION_BOARDS.REQUEST:
      return {
        ...state,
        journalsDiscussionBoards: {
          ...state.journalsDiscussionBoards,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case LOAD_JOURNAL_AND_DISCUSSION_BOARDS.SUCCESS: {
      return {
        ...state,
        journalsDiscussionBoards: {
          discussionBoards: action.payload.discussionBoards,
          journalTasks: action.payload.journalTasks,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    }
    case LOAD_JOURNAL_AND_DISCUSSION_BOARDS.ERROR:
      return {
        ...state,
        journalsDiscussionBoards: {
          ...state.journalsDiscussionBoards,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case UPDATE_MARKED_AS_INDIGENOUS.REQUEST:
      return {
        ...state,
        success: undefined,
        error: undefined,
      };
    case UPDATE_MARKED_AS_INDIGENOUS.SUCCESS:
      return {
        ...state,
        status: REDUX_STATUS.SUCCESS,
      };
    case UPDATE_MARKED_AS_INDIGENOUS.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case RESEND_IWL.REQUEST:
      return {
        ...state,
        success: undefined,
        error: undefined,
      };
    case RESEND_IWL.SUCCESS:
      return {
        ...state,
        status: REDUX_STATUS.SUCCESS,
      };
    case RESEND_IWL.ERROR:
      return {
        ...state,
        status: REDUX_STATUS.ERROR,
        error: action.payload,
      };

    case GET_IWL_STATUS.REQUEST:
      return {
        ...state,
        iwlStatus: {
          ...state.iwlStatus,
          status: REDUX_STATUS.PENDING,
          error: undefined,
        },
      };
    case GET_IWL_STATUS.SUCCESS:
      return {
        ...state,
        iwlStatus: {
          ...state.iwlStatus,
          data: action.payload,
          status: REDUX_STATUS.SUCCESS,
        },
      };
    case GET_IWL_STATUS.ERROR:
      return {
        ...state,
        iwlStatus: {
          ...state.iwlStatus,
          status: REDUX_STATUS.ERROR,
          error: action.payload,
        },
      };

    case RESET_GROUP_MEMBERSHIPS.SYNC:
      return initialState;

    default:
      return state;
  }
};
