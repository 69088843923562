import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'components/shared/FormFields/Elements/Textarea';

function TextareaField({ field, form, ...props }) {
  const { name, value, onChange } = field;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Textarea name={name} value={value} onChange={onChange} {...props} />
  );
}

TextareaField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    // https://formik.org/docs/api/field
  }).isRequired,
};

export default TextareaField;
