import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { titleCase } from 'lib/utils';
import { Button, CloseButton, Overlay, Popover } from 'react-bootstrap';
import SanyasIcon from 'components/shared/SanyasIcon';
import { LOAD_PARTICIPANT } from 'store/facilitatorDashboard/actions';
import Skeleton from 'components/shared/Skeleton';
import CopyButton from 'components/shared/Button/CopyButton';
import './ParticipantInfoPopover.scss';

function ParticipantInfoPopover({ participantId, displayName }) {
  const participant = useSelector((state) => state.facilitatorDashboard.participants.byId[participantId]);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show && !participant?.id) {
      dispatch(LOAD_PARTICIPANT.request(participantId));
    }
  }, [participantId, show, participant?.id, dispatch]);

  return (
    <>
      <Button variant={null} className="participant-info" ref={target} onClick={() => setShow(!show)}>
        <SanyasIcon name="profileInfo" />
      </Button>

      <Overlay
        target={target.current}
        placement="bottom-start"
        show={show}
        rootClose
        onHide={() => setShow(false)}
      >
        <Popover id={`participant-info-${participant?.id}`} className="participant-info-popover">
          <Popover.Body className="p-2">
            <div className="d-flex align-items-center">
              <div className="mb-0 fs-4 fw-semibold text-primary">
                {participant?.login || displayName || <Skeleton classes="title width-100 mb-0" />}
              </div>

              {!!participant?.login && (
                <CopyButton
                  variant={null}
                  size="sm"
                  className="copy-button ms-2"
                  result={participant.login}
                />
              )}
              <CloseButton className="ms-auto" onClick={() => setShow(false)} />
            </div>

            {!participant?.error && (
              <>
                <div className="user-detail">
                  {participant?.fullName || <Skeleton classes="text" />}
                  <br />
                  {participant?.decoratedCompletion || titleCase(participant?.completion) || <Skeleton classes="text" />}
                  {' '}
                  {participant?.percentageDoneCourse && `${participant?.percentageDoneCourse}%`}
                </div>

                <ul className="list-unstyled mb-2">
                  <li>
                    <span className="property-title">
                      Ancestry
                      {!!participant?.ancestryFacilitator && <SanyasIcon name="hummingbird" className="ms-1" />}
                    </span>
                    <span className="property-detail">
                      {participant?.ancestryFacilitator || participant?.ancestry || <Skeleton classes="text" />}
                    </span>
                  </li>
                  <li>
                    <span className="property-title">Age</span>
                    <span className="property-detail">{participant?.ageGroup || <Skeleton classes="text" />}</span>
                  </li>
                  <li>
                    <span className="property-title">{participant?.healthAuthorityLabel || 'Health Authority'}</span>
                    <span className="property-detail">
                      {participant?.healthAuthority || <Skeleton classes="text" />}
                      <span className="property-detail-secondary">{participant?.organization}</span>
                    </span>
                  </li>
                  <li>
                    <span className="property-title">Current Position</span>
                    <span className="property-detail">{participant?.jobCategory || <Skeleton classes="text" />}</span>
                  </li>
                </ul>
              </>
            )}

            {participant?.error && (
              <p className="text-danger">{participant?.error?.message}</p>
            )}

            {!!participant?.iwlStatus?.status && (
              <div className="mb-2 fw-semibold">
                <SanyasIcon name="flowerSolid" className="me-1 text-accent-purple" />
                {`IWL ${participant?.iwlStatus?.status}`}
              </div>
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
}

ParticipantInfoPopover.defaultProps = {
  displayName: null,
};

ParticipantInfoPopover.propTypes = {
  participantId: PropTypes.number.isRequired,
  displayName: PropTypes.string,
};

export default ParticipantInfoPopover;
