import React from 'react';
import PropTypes from 'prop-types';
import { useFieldContext } from 'lib/hooks/useFieldContext';
import { Editor } from '@tinymce/tinymce-react';

import 'tinymce/models/dom';

// Editor styles
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/icons/default';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';
import { init } from 'lib/tinyMce/utils';

function TinyMce({ disabled, options }) {
  const { id, value, handleChange } = useFieldContext();

  return (
    <Editor
      id={id}
      value={value}
      onEditorChange={handleChange}
      disabled={disabled}
      init={{
        ...init,
        ...options,
        plugins: [
          'advlist', 'autolink', 'autoresize', 'charmap', 'code', 'fullscreen',
          'image', 'link', 'lists', 'nonbreaking', 'searchreplace',
          'table', 'visualblocks', 'visualchars', 'wordcount',
        ],
        menubar: 'edit insert view format table',
        menu: {
          edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
          insert: { title: 'Insert', items: 'image link | charmap hr nonbreaking' },
          view: { title: 'View', items: 'visualchars visualblocks | fullscreen code' },
          format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | styles blocks align | forecolor backcolor | removeformat' },
          table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' },
        },
        toolbar: `undo redo | blocks | bold italic |  alignleft aligncenter alignright alignjustify |
          bullist numlist outdent indent | link image | fullscreen`,
        style_formats: [
          {
            title: 'Salish Eye Headings',
            items: [
              { title: 'Heading 1', block: 'h1', classes: 'salish-eye-red' },
              { title: 'Heading 2', block: 'h2', classes: 'salish-eye-red' },
            ],
          },
          {
            title: 'Image',
            items: [
              { title: 'Left', selector: 'img', classes: 'image-left' },
              { title: 'Right', selector: 'img', classes: 'image-right' },
              { title: 'Border', selector: 'img', classes: 'image-border' },
            ],
          },
        ],
        style_formats_merge: true,
        content_style: [contentUiCss, 'body { padding-inline: 0.5rem }'].join('\n'),
      }}
    />
  );
}

TinyMce.defaultProps = {
  disabled: false,
  options: {},
};

TinyMce.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.shape({}),
};

export default TinyMce;
