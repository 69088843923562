import { createSelector } from 'reselect';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import { selectHealthAuthorities } from 'store/healthAuthorities/selectors';

export const selectGroup = (state) => state.groupShow.group;
export const selectSubgroups = (state) => state.groupShow.subGroups;
export const selectGroupMemberships = (state) => state.groupShow.groupMemberships;
export const selectDiscussionsSections = (state) => state.groupShow.discussionsSections;
export const selectReservations = (state) => state.groupShow.reservations?.byId || {};
export const selectJournalData = (state) => state.groupShow.journals?.data || {};

export const selectGroupSubgroups = createSelector(
  selectGroup,
  selectSubgroups,
  (group, subGroups) => subGroups.filter((sg) => sg.parentGroupId === group.id),
);

export const selectAssignedGroupMemberships = createSelector(
  selectGroupMemberships,
  (gms) => _sortBy(
    Object.values(gms).filter((gm) => !gm.isFacilitator && !!gm.subGroupId),
    ['firstName', 'lastName'],
  ),
);

export const selectUnassignedGroupMemberships = createSelector(
  selectGroupMemberships,
  (gms) => _sortBy(
    Object.values(gms).filter((gm) => !gm.isFacilitator && !gm.subGroupId),
    ['firstName', 'lastName'],
  ),
);

export const selectUnassignedActiveGroupMemberships = createSelector(
  selectGroupMemberships,
  (gms) => _sortBy(
    Object.values(gms).filter((gm) => !gm.isFacilitator && !gm.subGroupId && gm.completion !== 'removed'),
    ['firstName', 'lastName'],
  ),
);

export const selectDiscussionsSectionsValues = createSelector(
  selectDiscussionsSections,
  (sections) => Object.values(sections),
);

export const selectSubgroupsValues = createSelector(
  selectSubgroups,
  (subGroups) => Object.values(subGroups),
);

export const selectReservationsByHealthAuthority = createSelector(
  selectReservations,
  selectHealthAuthorities,
  (_, groupOrganizationId) => groupOrganizationId,
  (reservations, healthAuthorities, groupOrganizationId) => Object.values(healthAuthorities)
    .filter(({ organizationId, parentId }) => organizationId === groupOrganizationId && !parentId)
    .map((healthAuthority) => {
      const reservation = _find(reservations, { healthAuthorityId: healthAuthority.id });
      // Regardless of whether the reservation exists, we want to return a consistent object
      return (
        {
          healthAuthorityName: healthAuthority.name,
          seats: reservation?.seats || 0,
          healthAuthorityId: healthAuthority.id,
          id: reservation?.id,
          _delete: false,
        }
      );
    }),
);

export const selectJournalDataByParticipantId = createSelector(
  selectJournalData,
  (_, id) => id,
  (journalData, id) => journalData.participations?.[id],
);
