import React from 'react';

function ProfileInfo() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.94412 16.6668C1.94412 16.6668 0.833008 16.6668 0.833008 15.5557C0.833008 14.4446 1.94412 11.1113 7.49967 11.1113C13.0552 11.1113 14.1663 14.4446 14.1663 15.5557C14.1663 16.6668 13.0552 16.6668 13.0552 16.6668H1.94412ZM7.49967 10.0002C8.38373 10.0002 9.23158 9.64897 9.8567 9.02385C10.4818 8.39873 10.833 7.55088 10.833 6.66683C10.833 5.78277 10.4818 4.93493 9.8567 4.30981C9.23158 3.68469 8.38373 3.3335 7.49967 3.3335C6.61562 3.3335 5.76777 3.68469 5.14265 4.30981C4.51753 4.93493 4.16634 5.78277 4.16634 6.66683C4.16634 7.55088 4.51753 8.39873 5.14265 9.02385C5.76777 9.64897 6.61562 10.0002 7.49967 10.0002V10.0002Z" fill="currentColor" />
      <g clipPath="url(#clip0_7297_5535)">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.8337 13.3333C16.9387 13.3333 17.9985 12.8943 18.7799 12.1129C19.5613 11.3315 20.0003 10.2717 20.0003 9.16667C20.0003 8.0616 19.5613 7.00179 18.7799 6.22039C17.9985 5.43899 16.9387 5 15.8337 5C14.7286 5 13.6688 5.43899 12.8874 6.22039C12.106 7.00179 11.667 8.0616 11.667 9.16667C11.667 10.2717 12.106 11.3315 12.8874 12.1129C13.6688 12.8943 14.7286 13.3333 15.8337 13.3333V13.3333ZM16.318 8.43125L15.1253 8.58073L15.0826 8.77865L15.317 8.82187C15.4701 8.85833 15.5003 8.91354 15.467 9.06615L15.0826 10.8724C14.9816 11.3396 15.1373 11.5594 15.5035 11.5594C15.7873 11.5594 16.117 11.4281 16.2665 11.2479L16.3123 11.0312C16.2081 11.1229 16.0561 11.1594 15.955 11.1594C15.8118 11.1594 15.7597 11.0589 15.7967 10.8818L16.318 8.43125V8.43125ZM15.8337 7.86458C15.9718 7.86458 16.1043 7.80971 16.2019 7.71203C16.2996 7.61436 16.3545 7.48188 16.3545 7.34375C16.3545 7.20562 16.2996 7.07314 16.2019 6.97547C16.1043 6.87779 15.9718 6.82292 15.8337 6.82292C15.6955 6.82292 15.563 6.87779 15.4654 6.97547C15.3677 7.07314 15.3128 7.20562 15.3128 7.34375C15.3128 7.48188 15.3677 7.61436 15.4654 7.71203C15.563 7.80971 15.6955 7.86458 15.8337 7.86458Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_7297_5535">
          <rect width="8.33333" height="8.33333" fill="white" transform="translate(11.667 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProfileInfo;
